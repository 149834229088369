import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CommonApiService } from 'src/app/services/common-api.service';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { UtilityTsService } from 'src/app/services/utility.ts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MasterService } from 'src/app/services/master.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-edit-three-years-and-above',
  templateUrl: './edit-three-years-and-above.component.html',
  styleUrls: ['./edit-three-years-and-above.component.scss'],
  providers: [MessageService],
})
export class EditThreeYearsAndAboveComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Edit 3 YEARS AND ABOVE',
      subTitle: 'Dashboard',
    },
  ];
  public flag: boolean = true;
  public playWith: boolean = true;
  public sleepTraining: boolean = true;
  public sleepWithNanny: boolean = true;
  public sleepWithMother: boolean = true;
  public skinCare: boolean = true;
  public shower: boolean = true;
  public tubBath: boolean = true;
  public allergy: boolean = true;
  public escort: boolean = true;
  public escortSchool: boolean = true;
  public educational: boolean = true;
  public play: boolean = true;
  public sleep: boolean = true;
  public machineWash: boolean = true;
  public handwash: boolean = true;
  public snack: boolean = true;
  public sponge: boolean = true;
  public tub: boolean = true;
  public dinner: boolean = true;
  public lunch: boolean = true;
  public breakfast: boolean = true;
  showNeedsTextarea: boolean = false;
  isSettingValueProgrammatically = true;
  isBathingtub: any = false;
  isBathingshower: any = false;
  currentDate: Date = new Date();
  selectedDate: Date = null; // Initialize with today's date
  years: any = '';
  month: any = '';
  days: any = '';
  frequency: any = [];
  tub_frequency: any = [];
  babyDetails: any = [];
  planId: any;
  FirstEntry: any;
  ismedicine: boolean = false;
  frequencyList: any = [
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
    { name: 4, value: 4 },
    { name: 5, value: 5 },
    { name: 6, value: 6 },
    { name: 7, value: 7 },
    { name: 8, value: 8 },
    { name: 9, value: 9 },
    { name: 10, value: 10 },
  ];
  // genderArray: any = [
  //   { name: 'Male', value: 'male' },
  //   { name: 'Female', value: 'female' },
  // ];

  threeYearAndAboveForm: FormGroup;
  babyGender: any;
  permissions: any = [];
  permissionData: any = [];
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private messageService: MessageService,
    private common_service: CommonApiService,
    private utility_service: UtilityTsService,
    private spinner: NgxSpinnerService,
    private masterService: MasterService,
    private confirmationService: ConfirmationService,
  ) {
    this.getAllUserPermission()
    this.threeYearAndAboveForm = this.fb.group({
      baby_details_id: new FormControl(null, Validators.required),
      baby_plan_id: new FormControl(null),
      feeding_prepared_mother: new FormControl(null),
      feeding_prepared_nanny: new FormControl(null),
      feeding_prepared_other: new FormControl(null),
      feeding_frequency: new FormControl(''),
      feeding_time: new FormControl(null),
      feeding_amount_per: new FormControl(null),
      type_of_formula: new FormControl(null),
      bottle_sterilization_all: new FormControl(null),
      bottle_sterilization_onetime: new FormControl(null),
      solid_other_snack: new FormControl(null),
      solid_other_snack_time: new FormControl({ value: null, disabled: true }),
      solid_other_snack_actual_time: new FormControl({ value: null, disabled: true }),
      soild_other_snack_food_components: new FormControl({
        value: null,
        disabled: true,
      }),
      regular_meal_type: new FormControl(null),
      regular_meal_dinner: new FormControl(null),
      regular_meal_dinner_time: new FormControl({
        value: null,
        disabled: true,
      }),
      regular_meal_dinner_food_components: new FormControl({
        value: null,
        disabled: true,
      }),
      regular_meal_time: new FormControl({ value: null, disabled: true }),
      regular_meal_lunch_food_components: new FormControl({
        value: null,
        disabled: true,
      }),
      regular_meal_lunch_time: new FormControl({ value: null, disabled: true }),
      regular_meal_lunch: new FormControl(),
      regular_meal_food_components: new FormControl({
        value: null,
        disabled: true,
      }),
      onetime: new FormControl(null),
      food_medicine_allergy: new FormControl(null),
      // food_medicine_allergy_no: new FormControl(null),
      food_medicine_allergy_specify_prod: new FormControl({
        value: null,
        disabled: true,
      }),
      bathing_tub: new FormControl(null),
      bathing_tub_time: new FormControl({ value: null, disabled: true }),
      bathing_tub_frequency: new FormControl({ value: null, disabled: true }),
      bathing_shower: new FormControl(null),
      bathing_shower_frequency: new FormControl({
        value: null,
        disabled: true,
      }),
      bathing_shower_time: new FormControl({ value: null, disabled: true }),
      wake_up_time: new FormControl(null),
      sleep_training: new FormControl(null),
      nap_time: new FormControl(null),
      sleep_time: new FormControl(null),
      bathing_time: new FormControl(null),
      skin_care_lotion: new FormControl(null),
      skin_care_baby_oil: new FormControl(null),
      skin_care_others: new FormControl(null),
      skin_care_specify_produ: new FormControl(null),
      skin_care_Wipes: new FormControl(null),
      skin_care_special_instructions: new FormControl(null),
      changing_colthes_frequency: new FormControl(null),
      changing_colthes_spiecal_instructions: new FormControl(null),
      sleeping_mother: new FormControl(null),
      sleeping_mother_time: new FormControl({ value: null, disabled: true }),
      sleeping_nanny: new FormControl(null),
      sleeping_nanny_time: new FormControl({ value: null, disabled: true }),
      temperature_monitoring_frequency: new FormControl(null),
      vitamins_medications_json: this.fb.array([]),
      vaccinations_json: this.fb.array([]),
      date_administration: new FormControl(null),
      potty_training: new FormControl(null),
      play_child_outside: new FormControl(null),
      play_child_outside_special_Instructions: new FormControl(null),
      escort_child_outside: new FormControl(null),
      escort_child_outside_special_Instructions: new FormControl(null),
      escort_child_school: new FormControl(null),
      escort_child_school_special_instructions: new FormControl(null),
      remarks: new FormControl(null),
      sleeping_independently: new FormControl(null),
      diaper_change_frequency: new FormControl(null),

      regular_food: this.fb.array([]),
      lunch_food: this.fb.array([]),
      dinner_food: this.fb.array([]),
      outside_child: this.fb.array([]),
      escort_child: this.fb.array([]),
      activities_name: this.fb.array([]),
      school_name: this.fb.array([]),
      child_training: this.fb.array([]),
      skin_care: this.fb.array([]),
      tub_bathing: this.fb.array([]),
      shower_bathing: this.fb.array([]),
      medicine_time: this.fb.array([]),
      changing_clothes: this.fb.array([])
      , sleeping_time: this.fb.array([]),
      nanny_time: this.fb.array([]),
      nurse_remarks: this.fb.array([]),

    });
  }
  isedit: boolean;
  // final submit button
  nannyTaskOnFinalSubmit() {
    // Show confirmation dialog
    this.threeYearAndAboveForm.enable();
    let structuredObject = {
      baby_details_id: this.threeYearAndAboveForm.get('baby_details_id').value,
      remarks:this.threeYearAndAboveForm.get('remarks').value,
      nurse_remarks:this.threeYearAndAboveForm.get('nurse_remarks').value,
      bathing:[
        {
         
          bathing_tub:this.threeYearAndAboveForm.get('bathing_tub').value,
          bathing_tub_frequency:this.threeYearAndAboveForm.get('bathing_tub_frequency').value,
          tub_bathing:this.threeYearAndAboveForm.get('tub_bathing').value,
          bathing_shower:this.threeYearAndAboveForm.get('bathing_shower').value,
          bathing_shower_frequency:this.threeYearAndAboveForm.get('bathing_shower_frequency').value,
          shower_bathing:this.threeYearAndAboveForm.get('shower_bathing').value,
        }
      ],
    
       clothes:[
        {
        changing_clothes:this.threeYearAndAboveForm.get('changing_clothes').value,
        changing_colthes_spiecal_instructions:this.threeYearAndAboveForm.get('changing_colthes_spiecal_instructions').value,
       }
      ],
      prepared:[
        {
          feeding_prepared_mother:this.threeYearAndAboveForm.get('feeding_prepared_mother').value,
          feeding_prepared_nanny:this.threeYearAndAboveForm.get('feeding_prepared_nanny').value,
           feeding_prepared_other: this.threeYearAndAboveForm.get('feeding_prepared_other').value,
        }
      ],
      sleeping:[{
        sleeping_time:this.threeYearAndAboveForm.get('sleeping_time').value,
        nanny_time:this.threeYearAndAboveForm.get('nanny_time').value,
      }],
      skin:[{
        skin_care:this.threeYearAndAboveForm.get('skin_care').value,
      }],
      vitamin:[
        {
          vitamins_medications_json:this.threeYearAndAboveForm.get('vitamins_medications_json').value,
        }
      ],
      wipes:[
        {
          skin_care_special_instructions:this.threeYearAndAboveForm.get('skin_care_special_instructions').value,
          skin_care_Wipes:this.threeYearAndAboveForm.get('skin_care_Wipes').value,
        }
      ],
      food:[{
        solid_food:this.threeYearAndAboveForm.get('regular_food').value,
        lunch_food:this.threeYearAndAboveForm.get('lunch_food').value,
        dinner_food:this.threeYearAndAboveForm.get('dinner_food').value,
        solid_other_snack_time:this.threeYearAndAboveForm.get('solid_other_snack_time').value,
        soild_other_snack_food_components:this.threeYearAndAboveForm.get('soild_other_snack_food_components').value,
      }],
      allergy:[
        {
          food_medicine_allergy:this.threeYearAndAboveForm.get('food_medicine_allergy').value,
          food_medicine_allergy_specify_prod:this.threeYearAndAboveForm.get('food_medicine_allergy_specify_prod').value,
        }
      ],
  
      potty_training:[
        {
          potty_training:this.threeYearAndAboveForm.get('child_training').value,
        }
      ],
      activities:[
        {
          outside_child:this.threeYearAndAboveForm.get('outside_child').value,
          escort_child:this.threeYearAndAboveForm.get('escort_child').value,
          activities_name:this.threeYearAndAboveForm.get('activities_name').value,
          school_name:this.threeYearAndAboveForm.get('school_name').value,
        }
      ]

  };
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Do you want to submit this task?',
      header: 'Submit Confirmation',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button-success p-button-text",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptIcon: "none",
      rejectIcon: "none",
      accept: () => {
        // If user confirms, proceed with submission
        let id = localStorage.getItem('babyId');
        let nurseid=  localStorage.getItem('nurseTaskEditID') 
       
      let payload = {
        nanny_task_details_json: JSON.stringify(this.threeYearAndAboveForm.value),
        structured_json: JSON.stringify(structuredObject),
        task_id :localStorage.getItem('nurseTaskEditID'),
        baby_id:localStorage.getItem('babyId'),
        baby_plan_id: 4,
      };
      const jsonString: string = JSON.stringify(this.threeYearAndAboveForm.value);
      // Correctly call the method from the service
      const processedData = this.utility_service.processFormData(JSON.parse(jsonString));
      payload.nanny_task_details_json = JSON.stringify(processedData)
        this.spinner.show();
        this.common_service.nannyfinaltasksubmit(id,payload)
          .then((res: any) => {
            this.spinner.hide();
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: res?.message,
            });
            setTimeout(() => {
              this.router.navigateByUrl('/portal/first-screen')
            }, 2000);
          })
          .catch((error: any) => {
            this.spinner.hide();
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error,
            });
          });
      },
      reject: () => {
        // If user rejects, show rejection message
        this.messageService.add({
          severity: 'info',
          summary: 'Rejected',
          detail: 'Submission cancelled',
        });
      }
    });
  }
  ngOnInit(): void {
    this.getBabyDetails();
    // for frequency dropdown list
    for (let i = 1; i <= 10; i++) {
      this.frequency.push({ frequency: i });
    }

    if (localStorage.getItem('nurseTaskEditID')) {
      this.getEditFormData();
      this.isedit = true;
    } else {
      this.isedit = false;
      this.getCreateFormData();
      // this.addVaccinationObject('add', 0);
      // this.addVitaminObject('add', 0);
      // this.addRegularObject('add', 0);
      // this.addLunchObject('add', 0);
      // this.addDinnerObject('add', 0);
      // this.addPlayObject('add', 0);
      // this.addEscortObject('add', 0);
      // this.addActiveObject('add', 0);
      // this.addSchoolObject('add', 0);
      // this.addTrainingObject('add', 0);
      // this.addSkinObject('add',0)
    }
    // this.addNurseRemark('add', 0);

  }






  getBabyDetails() {
    this.spinner.show()
    this.common_service.getBabyDetails().subscribe((res: any) => {
      this.spinner.hide()
      this.babyDetails = res?.data;
    }, (error: any) => {
      this.spinner.hide()
      // Handle any errors that occurred during the API call
      
    });
  }
  /* -------------------------------------------------------------------------- */
  /*            //Soft Reloading            */
  /* -------------------------------------------------------------------------- */
  softReload() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['portal/patient-view']);
    });
  }
  /* -------------------------------------------------------------------------- */
  /*               // get Baby Details on Baby name click function              */
  /* -------------------------------------------------------------------------- */
  getRemainingDetails(event: any) {
    let data = this.babyDetails?.filter((res: any) => res?.id == event?.value);
    let firstLetter = data[0]?.gender.toLowerCase();
    let capitalize =
      firstLetter.slice(0, 1).toUpperCase() +
      firstLetter.slice(1, firstLetter?.length);
    this.babyGender = capitalize;
    this.selectedDate = data[0].date_of_birth;
    // Manually parse the date string in DD/MM/YYYY format
    const dateParts = data[0].date_of_birth.split('/');
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed in JavaScript
    const year = parseInt(dateParts[2], 10);

    const selectedDate = moment([year, month, day]);
    const today = moment();

    // Calculate the difference in years, months, and days
    const years = today.diff(selectedDate, 'years');
    selectedDate.add(years, 'years');

    const months = today.diff(selectedDate, 'months');
    selectedDate.add(months, 'months');

    const days = today.diff(selectedDate, 'days');

    this.years = years;
    this.month = months;
    this.days = days;
    if (this.years >= 3) {
     
      this.flag = true;
    } else {

      this.flag = false;
      this.messageService.add({
        severity: 'info',
        summary: 'Info',
        detail:
          'Your child does not meet the eligibility criteria for this plan.',
      });
    }
  }
  /* -------------------------------------------------------------------------- */
  /*                      edit form function                            */
  /* -------------------------------------------------------------------------- */
  getCreateFormData() {
    this.spinner.show()
    
    const getEdit = localStorage.getItem('babyId');
    this.common_service.getEditNursetaskByBaby(getEdit).then((res: any) => {
      this.spinner.hide();
     
      const detailsInGrowthMonitoring = res.data[0];
      const nannytask = detailsInGrowthMonitoring.nanny_task_details_json;
      this.babyGender = detailsInGrowthMonitoring.gender;
      this.selectedDate = detailsInGrowthMonitoring.date_of_birth;
      this.days = detailsInGrowthMonitoring.days;
      this.month = detailsInGrowthMonitoring.months;
      this.years = detailsInGrowthMonitoring.years;
     
      const VitaminsMedication =
        res.data[0].nanny_task_details_json.vitamins_medications_json;
      const vaccine = res.data[0].nanny_task_details_json.vaccinations_json;
      const skin = res.data[0].nanny_task_details_json.skin_care;
      const nurseRemarks = res.data[0].nanny_task_details_json.nurse_remarks;
      const regular = res.data[0].nanny_task_details_json.regular_food;
      const lunch = res.data[0].nanny_task_details_json.lunch_food;
      const dinner = res.data[0].nanny_task_details_json.dinner_food;
      const training = res.data[0].nanny_task_details_json.child_training;
      const playing = res.data[0].nanny_task_details_json.outside_child;
      const escort = res.data[0].nanny_task_details_json.escort_child;
      const activity = res.data[0].nanny_task_details_json.activities_name;
      const school = res.data[0].nanny_task_details_json.school_name;
      this.threeYearAndAboveForm.controls.baby_details_id.setValue(
        JSON.parse(detailsInGrowthMonitoring.baby_details_id)
      );
     
      /* -------------------------------------------------------------------------- */
      /*                    set value of nanny task detail json into nanny task                         */
      /* -------------------------------------------------------------------------- */
      this.threeYearAndAboveForm.controls.feeding_prepared_mother.setValue(
        nannytask.feeding_prepared_mother
      );
      this.threeYearAndAboveForm.controls.feeding_prepared_other.setValue(
        nannytask.feeding_prepared_other
      );
      this.threeYearAndAboveForm.controls.feeding_prepared_nanny.setValue(
        nannytask.feeding_prepared_nanny
      );
      // this.threeYearAndAboveForm.controls.feeding_type.setValue(
      //   nannytask.feeding_type
      // );
      // this.threeYearAndAboveForm.controls.feeding_breast.setValue(
      //   nannytask.feeding_breast
      // );
      this.threeYearAndAboveForm.controls.solid_other_snack.setValue(
        nannytask.solid_other_snack
      );
      this.threeYearAndAboveForm.controls.regular_meal_type.setValue(
        nannytask.regular_meal_type
      );
      this.threeYearAndAboveForm.controls.bathing_shower.setValue(
        nannytask.bathing_shower
      );
      this.threeYearAndAboveForm.controls.bathing_tub.setValue(
        nannytask.bathing_tub
      );
      this.threeYearAndAboveForm.controls.sleeping_mother.setValue(
        nannytask.sleeping_mother
      );
      this.threeYearAndAboveForm.controls.sleeping_nanny.setValue(
        nannytask.sleeping_nanny
      );
      this.threeYearAndAboveForm.controls.temperature_monitoring_frequency.setValue(
        nannytask.temperature_monitoring_frequency
      );
      this.threeYearAndAboveForm.controls.sleeping_independently.setValue(
        nannytask.sleeping_independently
      );
      this.threeYearAndAboveForm.controls.changing_colthes_spiecal_instructions.setValue(
        nannytask.changing_colthes_spiecal_instructions
      );
      this.threeYearAndAboveForm.controls.changing_colthes_frequency.setValue(
        nannytask.changing_colthes_frequency
      );
      this.threeYearAndAboveForm.controls.food_medicine_allergy.setValue(
        nannytask.food_medicine_allergy
      );
      //skin care add button on edit
      for (let i = 0; i < skin?.length; i++) {
        let skinArray = this.getSkinFormArray();
        let skinFromApi = skin[i] || {};

        let newskinArray = new FormGroup({
          skin_care_lotion: new FormControl(skinFromApi.skin_care_lotion || ''),
          skin_care_baby_oil: new FormControl(skinFromApi.skin_care_baby_oil || ''),
          skin_care_others: new FormControl(skinFromApi.skin_care_others || ''),
          skin_care_specify_produ: new FormControl(skinFromApi.skin_care_specify_produ || ''),
          nurses: new FormControl(skinFromApi.nurses||null)
        });

        skinArray.insert(i, newskinArray);
      }

      for (let i = 0; i < nurseRemarks?.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getNurseRemarks();
        let time = nurseRemarks[i] || {};

        const newCalculationGroup = this.fb.group({
          nurse_remark: new FormControl(time.nurse_remark ? time.nurse_remark : null),
          nurse_remark_time: new FormControl(time.nurse_remark_time ? time.nurse_remark_time : null),
          nurses: new FormControl(time.nurses||null)
        });
        CalculationArray.push(newCalculationGroup);
      }

      if (nurseRemarks?.length < 1 || nurseRemarks == undefined) {
        this.addNurseRemark('add', 0);
      }
      //sleeping mother
      const sleeptime = res.data[0].nanny_task_details_json.sleeping_time;
      for (let i = 0; i < sleeptime?.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getSleepArray();
        let time = sleeptime[i] || {};

        const newCalculationGroup = this.fb.group({
          sleeping_mother_time: new FormControl(
            time.sleeping_mother_time
              ? new Date(time.sleeping_mother_time)
              : null
          ),

          sleeping_mother_actual_time: new FormControl(
            time.sleeping_mother_actual_time
              ? new Date(time.sleeping_mother_actual_time)
              : null
          ),
          sleeping_mother: new FormControl(time.sleeping_mother || ''),
          nurses: new FormControl(time.nurses||null)
        });
        CalculationArray.push(newCalculationGroup);
      }
      //nanny time
      const nannytime = res.data[0].nanny_task_details_json.nanny_time;
      for (let i = 0; i < nannytime?.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getSleepnannyArray();
        let time = nannytime[i] || {};

        const newCalculationGroup = this.fb.group({
          sleeping_nanny_time: new FormControl(
            time.sleeping_nanny_time
              ? new Date(time.sleeping_nanny_time)
              : null
          ),
          sleeping_nanny_actual_time: new FormControl(
            time.sleeping_nanny_actual_time
              ? new Date(time.sleeping_nanny_actual_time)
              : null
          ),
          sleeping_nanny: new FormControl(time.sleeping_nanny || ''),
          nurses: new FormControl(time.nurses||null)
        });
        CalculationArray.push(newCalculationGroup);
      }
      /* -------------------------------------------------------------------------- */
      /*                    set value of vitamin json into nanny task                */
      /* -------------------------------------------------------------------------- */
      for (let i = 0; i < VitaminsMedication?.length; i++) {
        let vitaminArray = this.getVitaminFormArray();
        let VitaminsMedicationFromApi = VitaminsMedication[i] || {};

        let newVitaminArray = new FormGroup({
          vitamin: new FormControl(VitaminsMedicationFromApi.vitamin || ''),
          medicine_time: new FormArray([]),
          frequency: new FormControl(VitaminsMedicationFromApi.frequency || ''),
          nurses: new FormControl(VitaminsMedicationFromApi.nurses||null)
        });

        vitaminArray.insert(i, newVitaminArray);

        this.ismedicine = true;
        for (let j = 0; j < VitaminsMedicationFromApi.medicine_time?.length; j++) {

          const viamincalc = (this.threeYearAndAboveForm.get('vitamins_medications_json') as FormArray).at(i).get('medicine_time') as FormArray;
          const bathingcalculationgroup = this.fb.group({
            time: new FormControl(new Date(VitaminsMedicationFromApi.medicine_time[j].time)),
            actual_time: new FormControl(VitaminsMedicationFromApi.medicine_time[j].actual_time ? new Date(VitaminsMedicationFromApi.medicine_time[j].actual_time) : ''),

            vitamin_checkbox: new FormControl(VitaminsMedicationFromApi.medicine_time[j].vitamin_checkbox),
            vitamin_remark: new FormControl(VitaminsMedicationFromApi.medicine_time[j].vitamin_remark),
            nurses: new FormControl(VitaminsMedicationFromApi.medicine_time[j].nurses)          });
          viamincalc.push(bathingcalculationgroup);
        }
      }
      /* -------------------------------------------------------------------------- */
      /*                    set value of vaccine json into nanny task                */
      /* -------------------------------------------------------------------------- */
      // for (let i = 0; i < vaccine?.length; i++) {
      //   let vaccinationArray = this.getVaccinationFormArray();
      //   let vaccineApi = vaccine[i] || {};

      //   let newVaccinationArray = new FormGroup({
      //     vaccination: new FormControl(vaccineApi.vaccination || ''),
      //     date: new FormControl(
      //       vaccineApi.date ? new Date(vaccineApi.date) : null
      //     ),
      //   });

      //   vaccinationArray.insert(i, newVaccinationArray);
      // }
      /* -------------------------------------------------------------------------- */
      /*                    set value of regular meal json into nanny task                */
      /* -------------------------------------------------------------------------- */
      for (let i = 0; i < regular?.length; i++) {
        let RegularArray = this.getRegularFormArray();
        let regularApi = regular[i] || {};

        let newRegularArray = new FormGroup({
          regular_meal_type: new FormControl(
            regularApi.regular_meal_type || ''
          ),
          regular_meal_time: new FormControl(
            regularApi.regular_meal_time
              ? new Date(regularApi.regular_meal_time)
              : null
          ),

          regular_meal_actual_time: new FormControl(
            regularApi.regular_meal_actual_time
              ? new Date(regularApi.regular_meal_actual_time)
              : null
          ),

          regular_meal_food_components: new FormControl(
            regularApi.regular_meal_food_components || ''
          ),
          nurses: new FormControl(regularApi.nurses||null)
        });

        RegularArray.insert(i, newRegularArray);
      }
      /* -------------------------------------------------------------------------- */
      /*                    set value of  lunch json into nanny task                         */
      /* -------------------------------------------------------------------------- */
      for (let i = 0; i < lunch?.length; i++) {
        let LunchArray = this.getLunchFormArray();
        let lunchApi = lunch[i] || {};

        let newLunchArray = new FormGroup({
          regular_meal_lunch: new FormControl(
            lunchApi.regular_meal_lunch || ''
          ),
          regular_meal_lunch_time: new FormControl(
            lunchApi.regular_meal_lunch_time
              ? new Date(lunchApi.regular_meal_lunch_time)
              : null
          ),
          regular_meal_lunch_actual_time: new FormControl(
            lunchApi.regular_meal_lunch_actual_time
              ? new Date(lunchApi.regular_meal_lunch_actual_time)
              : null
          ),
          regular_meal_lunch_food_components: new FormControl(
            lunchApi.regular_meal_lunch_food_components || ''
          ),
          nurses: new FormControl(lunchApi.nurses||null)
        });

        LunchArray.insert(i, newLunchArray);
      }
      /* -------------------------------------------------------------------------- */
      /*                    set value of  dinner json into nanny task                         */
      /* -------------------------------------------------------------------------- */
      for (let i = 0; i < dinner?.length; i++) {
        let dinnerArray = this.getDinnerFormArray();
        let dinnerApi = dinner[i] || {};

        let newdinnerArray = new FormGroup({
          regular_meal_dinner: new FormControl(
            dinnerApi.regular_meal_dinner || ''
          ),
          regular_meal_dinner_time: new FormControl(
            dinnerApi.regular_meal_dinner_time
              ? new Date(dinnerApi.regular_meal_dinner_time)
              : null
          ),

          regular_meal_dinner_actual_time: new FormControl(
            dinnerApi.regular_meal_dinner_actual_time
              ? new Date(dinnerApi.regular_meal_dinner_actual_time)
              : null
          ),
          regular_meal_dinner_food_components: new FormControl(
            dinnerApi.regular_meal_dinner_food_components || ''
          ),
          nurses: new FormControl(dinnerApi.nurses||null)
        });

        dinnerArray.insert(i, newdinnerArray);
      }
      /* -------------------------------------------------------------------------- */
      /*                    set value of  training json into nanny task                         */
      /* -------------------------------------------------------------------------- */
      for (let i = 0; i < training?.length; i++) {
        let TrainingArray = this.getTrainingFormArray();
        let trainingApi = training[i] || {};

        let newTrainingArray = new FormGroup({
          potty_training: new FormControl(trainingApi.potty_training || ''),
          training_child: new FormControl(trainingApi.training_child || ''),
          done_training_child: new FormControl(trainingApi.done_training_child || ''),
          time_training_child: new FormControl(
            trainingApi.time_training_child
              ? new Date(trainingApi.time_training_child)
              : null
          ),

          time_training_actual_child: new FormControl(
            trainingApi.time_training_actual_child
              ? new Date(trainingApi.time_training_actual_child)
              : null
          ),
          training_child_remark: new FormControl(trainingApi.training_child_remark || ''),
          nurses: new FormControl(trainingApi.nurses||null)
        });

        TrainingArray.insert(i, newTrainingArray);
      }
      /* -------------------------------------------------------------------------- */
      /*                    set value of  playing json into nanny task                         */
      /* -------------------------------------------------------------------------- */
      for (let i = 0; i < playing?.length; i++) {
        let playingArray = this.getPlayFormArray();
        let playApi = playing[i] || {};

        let newpalyingArray = new FormGroup({
          play_child_outside: new FormControl(playApi.play_child_outside || ''),
          play_child_outside_special_Instructions: new FormControl(
            playApi.play_child_outside_special_Instructions || ''
          ),
          plsy_child_outside_done: new FormControl(playApi.plsy_child_outside_done || ''),
          play_child_outside_time: new FormControl(
            playApi.play_child_outside_time
              ? new Date(playApi.play_child_outside_time)
              : null
          ),
          play_child_outside_actual_time: new FormControl(
            playApi.play_child_outside_actual_time
              ? new Date(playApi.play_child_outside_actual_time)
              : null
          ),
          play_child_outside_remark: new FormControl(playApi.play_child_outside_remark || ''),
          play_child_outside_done: new FormControl(playApi.play_child_outside_done || ''),
          nurses: new FormControl(playApi.nurses||null)
        });

        playingArray.insert(i, newpalyingArray);
      }
      //escort value
      for (let i = 0; i < escort?.length; i++) {
        let activeArray = this.getescortFormArray();
        let NewSleepingApi = escort[i] || {};

        let newTrainingArray = new FormGroup({
          escort_child_outside: new FormControl(
            NewSleepingApi.escort_child_outside || ''
          ),
          escort_child_outside_special_Instructions: new FormControl(
            NewSleepingApi.escort_child_outside_special_Instructions || ''
          ),
          escort_child_outside_done: new FormControl(NewSleepingApi.escort_child_outside_done || ''),
          escort_child_outside_time: new FormControl(
            NewSleepingApi.escort_child_outside_time
              ? new Date(NewSleepingApi.escort_child_outside_time)
              : null
          ),
          escort_child_outside_actual_time: new FormControl(
            NewSleepingApi.escort_child_outside_actual_time
              ? new Date(NewSleepingApi.escort_child_outside_actual_time)
              : null
          ),

          escort_child_outside_remark: new FormControl(NewSleepingApi.escort_child_outside_remark || ''),
          nurses: new FormControl(NewSleepingApi.nurses||null)
        });

        activeArray.insert(i, newTrainingArray);
      }
      /* -------------------------------------------------------------------------- */
      /*                    set value of  extra activities json into nanny task                         */
      /* -------------------------------------------------------------------------- */
      for (let i = 0; i < activity?.length; i++) {
        let escortArray = this.getActiveFormArray();
        let NewescortApi = activity[i] || {};

        let newescortArray = new FormGroup({
          other_activity: new FormControl(NewescortApi.other_activity || ''),
          activities_child_outside_special_Instructions: new FormControl(
            NewescortApi.activities_child_outside_special_Instructions || ''
          ),
          other_child_outside_time: new FormControl(
            NewescortApi.other_child_outside_time
              ? new Date(NewescortApi.other_child_outside_time)
              : null
          ),
          other_child_outside_actual_time: new FormControl(
            NewescortApi.other_child_outside_actual_time
              ? new Date(NewescortApi.other_child_outside_actual_time)
              : null
          ),
          other_child_outside_remark: new FormControl(NewescortApi.other_child_outside_remark || ''),
          other_child_outside_done: new FormControl(NewescortApi.other_child_outside_done || ''),
          nurses: new FormControl(NewescortApi.nurses||null)
        });

        escortArray.insert(i, newescortArray);
      }
      /* -------------------------------------------------------------------------- */
      /*                    set value of  school json into nanny task                         */
      /* -------------------------------------------------------------------------- */
      for (let i = 0; i < school?.length; i++) {
        let schoolArray = this.getSchoolFormArray();
        let schoolApi = school[i] || {};

        let newschoolArray = new FormGroup({
          escort_child_school: new FormControl(
            schoolApi.escort_child_school || ''
          ),
          escort_child_school_special_instructions: new FormControl(
            schoolApi.escort_child_school_special_instructions || ''
          ),
          school_child_outside_time: new FormControl(
            schoolApi.school_child_outside_time
              ? new Date(schoolApi.school_child_outside_time)
              : null
          ),

          school_child_outside_actual_time: new FormControl(
            schoolApi.school_child_outside_actual_time
              ? new Date(schoolApi.school_child_outside_actual_time)
              : null
          ),
          school_child_outside_remark: new FormControl(schoolApi.school_child_outside_remark || ''),
          school_child_outside_done: new FormControl(schoolApi.school_child_outside_done || ''),
           nurses: new FormControl(schoolApi.nurses||null)
        });

        schoolArray.insert(i, newschoolArray);
      }
      // set value of solid_other_snack
      this.threeYearAndAboveForm.controls.soild_other_snack_food_components.setValue(nannytask.soild_other_snack_food_components);
      this.threeYearAndAboveForm.controls.solid_other_snack_time.setValue(this.utility_service.parseISOString(nannytask.solid_other_snack_time));
      this.threeYearAndAboveForm.controls.solid_other_snack_actual_time.setValue(this.utility_service.parseISOString(nannytask.solid_other_snack_actual_time));
      // if (nannytask.solid_other_snack == true) {
      //   this.threeYearAndAboveForm.controls['solid_other_snack_time'].enable();
      //   this.threeYearAndAboveForm.controls['solid_other_snack_actual_time'].enable();
      //   this.threeYearAndAboveForm.controls[
      //     'soild_other_snack_food_components'
      //   ].enable();
      //   this.threeYearAndAboveForm.controls.solid_other_snack_time.setValue(
      //     this.utility_service.parseISOString(nannytask.solid_other_snack_time)
      //   );
      //   this.threeYearAndAboveForm.controls.solid_other_snack_actual_time.setValue(
      //     this.utility_service.parseISOString(nannytask.solid_other_snack_actual_time)
      //   );
      //   this.threeYearAndAboveForm.controls.soild_other_snack_food_components.setValue(
      //     nannytask.soild_other_snack_food_components
      //   );
      // } else {
      //   this.threeYearAndAboveForm.controls['solid_other_snack_time'].disable();
      //   this.threeYearAndAboveForm.controls['solid_other_snack_actual_time'].disable();
      //   this.threeYearAndAboveForm.controls[
      //     'soild_other_snack_food_components'
      //   ].disable();
      // }
      // set value of regular_meal_type
      // if (nannytask.regular_meal_type == true) {
      //   this.threeYearAndAboveForm.controls['regular_meal_time'].enable();
      //   this.threeYearAndAboveForm.controls[
      //     'regular_meal_food_components'
      //   ].enable();
      //   this.threeYearAndAboveForm.controls.regular_meal_time.setValue(
      //     this.utility_service.parseISOString(nannytask.regular_meal_time)
      //   );
      //   this.threeYearAndAboveForm.controls.regular_meal_food_components.setValue(
      //     nannytask.regular_meal_food_components
      //   );
      // } else {
      //   this.threeYearAndAboveForm.controls['regular_meal_time'].disable();
      //   this.threeYearAndAboveForm.controls[
      //     'regular_meal_food_components'
      //   ].disable();
      // }
      // set value of regular_meal_lunch
      // if (nannytask.regular_meal_lunch == true) {
      //   this.threeYearAndAboveForm.controls['regular_meal_lunch_time'].enable();
      //   this.threeYearAndAboveForm.controls[
      //     'regular_meal_lunch_food_components'
      //   ].enable();
      //   this.threeYearAndAboveForm.controls.regular_meal_lunch_time.setValue(
      //     this.utility_service.parseISOString(nannytask.regular_meal_lunch_time)
      //   );
      //   this.threeYearAndAboveForm.controls.regular_meal_lunch_food_components.setValue(
      //     nannytask.regular_meal_lunch_food_components
      //   );
      // } else {
      //   this.threeYearAndAboveForm.controls[
      //     'regular_meal_lunch_time'
      //   ].disable();
      //   this.threeYearAndAboveForm.controls[
      //     'regular_meal_lunch_food_components'
      //   ].disable();
      // }
      //set value of regular dinner
      // if (nannytask.regular_meal_dinner == true) {
      //   this.threeYearAndAboveForm.controls[
      //     'regular_meal_dinner_time'
      //   ].enable();
      //   this.threeYearAndAboveForm.controls[
      //     'regular_meal_dinner_food_components'
      //   ].enable();
      //   this.threeYearAndAboveForm.controls.regular_meal_dinner_time.setValue(
      //     this.utility_service.parseISOString(
      //       nannytask.regular_meal_dinner_time
      //     )
      //   );
      //   this.threeYearAndAboveForm.controls.regular_meal_dinner_food_components.setValue(
      //     nannytask.regular_meal_dinner_food_components
      //   );
      // } else {
      //   this.threeYearAndAboveForm.controls[
      //     'regular_meal_dinner_time'
      //   ].disable();
      //   this.threeYearAndAboveForm.controls[
      //     'regular_meal_dinner_food_components'
      //   ].disable();
      // }
      // set value of bathing shower
      if (nannytask.bathing_shower == true) {
        this.threeYearAndAboveForm.controls['bathing_shower_time'].enable();
        this.threeYearAndAboveForm.controls[
          'bathing_shower_frequency'
        ].enable();
        // this.threeYearAndAboveForm.controls.bathing_shower_time.setValue(
        //   this.utility_service.parseISOString(nannytask.bathing_shower_time)
        // );
        this.threeYearAndAboveForm.controls.bathing_shower_frequency.setValue(
          nannytask.bathing_shower_frequency
        );
      } else {
        this.threeYearAndAboveForm.controls['bathing_shower_time'].disable();
        // this.threeYearAndAboveForm.controls[
        //   'bathing_shower_frequency'
        // ].disable();
      }
      // set value of bathing tub
      if (nannytask.bathing_tub == true) {
        this.threeYearAndAboveForm.controls['bathing_tub_time'].enable();
        this.threeYearAndAboveForm.controls['bathing_tub_frequency'].enable();
        // this.threeYearAndAboveForm.controls.bathing_tub_time.setValue(
        //   this.utility_service.parseISOString(nannytask.bathing_tub_time)
        // );
        this.threeYearAndAboveForm.controls.bathing_tub_frequency.setValue(
          nannytask.bathing_tub_frequency
        );
      } else {
        // this.threeYearAndAboveForm.controls['bathing_tub_time'].disable();
        this.threeYearAndAboveForm.controls['bathing_tub_frequency'].disable();
      }

      if (nannytask.food_medicine_allergy == '1') {
        this.threeYearAndAboveForm.controls[
          'food_medicine_allergy_specify_prod'
        ].enable();
        this.threeYearAndAboveForm.controls.food_medicine_allergy_specify_prod.setValue(
          nannytask.food_medicine_allergy_specify_prod
        );
      } else {
        this.threeYearAndAboveForm.controls[
          'food_medicine_allergy_specify_prod'
        ].disable();
      }
      this.threeYearAndAboveForm.controls.skin_care_lotion.setValue(
        nannytask.skin_care_lotion
      );
      this.threeYearAndAboveForm.controls.skin_care_baby_oil.setValue(
        nannytask.skin_care_baby_oil
      );
      this.threeYearAndAboveForm.controls.skin_care_others.setValue(
        nannytask.skin_care_others
      );
      this.threeYearAndAboveForm.controls.skin_care_specify_produ.setValue(
        nannytask.skin_care_specify_produ
      );
      this.threeYearAndAboveForm.controls.skin_care_Wipes.setValue(
        nannytask.skin_care_Wipes
      );
      this.threeYearAndAboveForm.controls.bottle_sterilization_all.setValue(
        nannytask.bottle_sterilization_all
      );
      this.threeYearAndAboveForm.controls.bottle_sterilization_all.setValue(
        nannytask.bottle_sterilization_onetime
      );

      if (nannytask.bottle_sterilization_all == true) {
        this.threeYearAndAboveForm.controls.bottle_sterilization_all_time.enable();
        this.threeYearAndAboveForm.controls.bottle_sterilization_all_time.setValue(
          this.utility_service.parseISOString(
            nannytask.bottle_sterilization_all_time
          )
        );
      } else {
        this.threeYearAndAboveForm.controls.bottle_sterilization_all.disable();
      }

      if (nannytask.skin_care_Wipes == '1') {
        this.threeYearAndAboveForm.controls['skin_care_special_instructions']
          .enable;
        this.threeYearAndAboveForm.controls.skin_care_special_instructions.setValue(
          nannytask.skin_care_special_instructions
        );
      } else {
        this.threeYearAndAboveForm.controls[
          'skin_care_special_instructions'
        ].disable();
      }

      this.threeYearAndAboveForm.controls.remarks.setValue(nannytask.remarks);
      if (nannytask.sleeping_mother == '1') {
        this.threeYearAndAboveForm.controls.sleeping_mother_time.enable();
        this.threeYearAndAboveForm.controls.sleeping_mother_time.setValue(
          this.utility_service.parseISOString(nannytask.sleeping_mother_time)
        );
      } else {
        this.threeYearAndAboveForm.controls.sleeping_mother_time.disable();
      }
      if (nannytask.sleeping_nanny == '1') {
        this.threeYearAndAboveForm.controls.sleeping_nanny_time.enable();
        this.threeYearAndAboveForm.controls.sleeping_nanny_time.setValue(
          this.utility_service.parseISOString(nannytask.sleeping_nanny_time)
        );
      } else {
        this.threeYearAndAboveForm.controls.sleeping_nanny_time.disable();
      }
      this.threeYearAndAboveForm.controls.diaper_change_frequency.setValue(nannytask.diaper_change_frequency);

      // clothes
      const changingclothes =
        res.data[0].nanny_task_details_json.changing_clothes;
      for (let i = 0; i < changingclothes?.length; i++) {
        let clothesarray = this.getClothesFormArray();
        let clothes = changingclothes[i] || {};

        const newclothesGroup = this.fb.group({
          changing_colthes: new FormControl(clothes.changing_colthes || ''),
          Changing_time: new FormControl(
            clothes.Changing_time
              ? new Date(clothes.Changing_time)
              : null
          ),
          Changing_actual_time: new FormControl(
            clothes.Changing_actual_time
              ? new Date(clothes.Changing_actual_time)
              : null
          ),
          clothe_remark: new FormControl(clothes.clothe_remark || ''),
          nurses: new FormControl(clothes.nurses||null)
        });
        clothesarray.push(newclothesGroup);
      }
      // set value of diaper changes

      //bathing section

      const tubtime = res.data[0].nanny_task_details_json.tub_bathing;
      for (let i = 0; i < tubtime?.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getbathingtubarray();
        let time = tubtime[i] || {};

        const newCalculationGroup = this.fb.group({
          bathing_tub_time: new FormControl(
            time.bathing_tub_time
              ? new Date(time.bathing_tub_time)
              : null
          ),
          bathing_tub_actual_time: new FormControl(
            time.bathing_tub_actual_time
              ? new Date(time.bathing_tub_actual_time)
              : null
          ),
          // bathing_tub_actual_time: new FormControl(
          //   new Date(time.bathing_tub_actual_time ? time.bathing_tub_actual_time : null)
          // ),
          tub_checkbox: new FormControl(time.tub_checkbox || ''),
          tubBathing_remark: new FormControl(time.tubBathing_remark || ''),
          nurses: new FormControl(time.nurses||null)
        });
        CalculationArray.push(newCalculationGroup);
      }
      const showertime = res.data[0].nanny_task_details_json.shower_bathing;
      for (let i = 0; i < showertime?.length; i++) {
        this.isBathingshower = true;
        let CalculationArray = this.getshowertubarray();
        let time = showertime[i] || {};

        const newCalculationGroup = this.fb.group({
          bathing_shower_time: new FormControl(
            time.bathing_shower_time
              ? new Date(time.bathing_shower_time)
              : null
          ),
          bathing_shower_actual_time: new FormControl(
            time.bathing_shower_actual_time
              ? new Date(time.bathing_shower_actual_time)
              : null
          ),


          shower_checkbox: new FormControl(time.shower_checkbox || ''),
          shower_remark: new FormControl(time.shower_remark || ''),
          nurses: new FormControl(time.nurses||null)
        });
        CalculationArray.push(newCalculationGroup);
      }
      this.isSettingValueProgrammatically = false;
      this.disableForm();

    }, (error: any) => {
      this.spinner.hide()

      // Handle any errors that occurred during the API call

    });
  }
  getEditFormData() {
    this.spinner.show()
    
    const getEdit = localStorage.getItem('nurseTaskEditID');
    this.common_service.getEditNurseDailyTask(getEdit).then((res: any) => {
      this.spinner.hide()
     
      const detailsInGrowthMonitoring = res.data[0];
      const nannytask = detailsInGrowthMonitoring.nanny_task_details_json;
      this.FirstEntry = nannytask
     
      this.babyGender = detailsInGrowthMonitoring.gender;
      this.selectedDate = detailsInGrowthMonitoring.date_of_birth;
      this.days = detailsInGrowthMonitoring.days;
      this.month = detailsInGrowthMonitoring.months;
      this.years = detailsInGrowthMonitoring.years;
   
      const VitaminsMedication =
        res.data[0].nanny_task_details_json.vitamins_medications_json;
      const vaccine = res.data[0].nanny_task_details_json.vaccinations_json;
      const skin = res.data[0].nanny_task_details_json.skin_care;
      const nurseRemarks = res.data[0].nanny_task_details_json.nurse_remarks;
      const regular = res.data[0].nanny_task_details_json.regular_food;
      const lunch = res.data[0].nanny_task_details_json.lunch_food;
      const dinner = res.data[0].nanny_task_details_json.dinner_food;
      const training = res.data[0].nanny_task_details_json.child_training;
      const playing = res.data[0].nanny_task_details_json.outside_child;
      const escort = res.data[0].nanny_task_details_json.escort_child;
      const activity = res.data[0].nanny_task_details_json.activities_name;
      const school = res.data[0].nanny_task_details_json.school_name;
      this.threeYearAndAboveForm.controls.baby_details_id.setValue(
        JSON.parse(detailsInGrowthMonitoring.baby_details_id)
      );
     
      /* -------------------------------------------------------------------------- */
      /*                    set value of nanny task detail json into nanny task                         */
      /* -------------------------------------------------------------------------- */
      this.threeYearAndAboveForm.controls.feeding_prepared_mother.setValue(
        nannytask.feeding_prepared_mother
      );
      this.threeYearAndAboveForm.controls.feeding_prepared_other.setValue(
        nannytask.feeding_prepared_other
      );
      this.threeYearAndAboveForm.controls.feeding_prepared_nanny.setValue(
        nannytask.feeding_prepared_nanny
      );
      // this.threeYearAndAboveForm.controls.feeding_type.setValue(
      //   nannytask.feeding_type
      // );
      // this.threeYearAndAboveForm.controls.feeding_breast.setValue(
      //   nannytask.feeding_breast
      // );
      this.threeYearAndAboveForm.controls.solid_other_snack.setValue(
        nannytask.solid_other_snack
      );
      this.threeYearAndAboveForm.controls.regular_meal_type.setValue(
        nannytask.regular_meal_type
      );
      this.threeYearAndAboveForm.controls.bathing_shower.setValue(
        nannytask.bathing_shower
      );
      this.threeYearAndAboveForm.controls.bathing_tub.setValue(
        nannytask.bathing_tub
      );
      this.threeYearAndAboveForm.controls.sleeping_mother.setValue(
        nannytask.sleeping_mother
      );
      this.threeYearAndAboveForm.controls.sleeping_nanny.setValue(
        nannytask.sleeping_nanny
      );
      this.threeYearAndAboveForm.controls.temperature_monitoring_frequency.setValue(
        nannytask.temperature_monitoring_frequency
      );
      this.threeYearAndAboveForm.controls.sleeping_independently.setValue(
        nannytask.sleeping_independently
      );
      this.threeYearAndAboveForm.controls.changing_colthes_spiecal_instructions.setValue(
        nannytask.changing_colthes_spiecal_instructions
      );
      this.threeYearAndAboveForm.controls.changing_colthes_frequency.setValue(
        nannytask.changing_colthes_frequency
      );
      this.threeYearAndAboveForm.controls.food_medicine_allergy.setValue(
        nannytask.food_medicine_allergy
      );
      //skin care add button on edit
      for (let i = 0; i < skin?.length; i++) {
        let skinArray = this.getSkinFormArray();
        let skinFromApi = skin[i] || {};

        let newskinArray = new FormGroup({
          skin_care_lotion: new FormControl(skinFromApi.skin_care_lotion || ''),
          skin_care_baby_oil: new FormControl(skinFromApi.skin_care_baby_oil || ''),
          skin_care_others: new FormControl(skinFromApi.skin_care_others || ''),
          skin_care_specify_produ: new FormControl(skinFromApi.skin_care_specify_produ || ''),
        });

        skinArray.insert(i, newskinArray);
      }

      if (nurseRemarks != undefined) {
        for (let i = 0; i < nurseRemarks?.length; i++) {
          this.isBathingtub = true;
          let CalculationArray = this.getNurseRemarks();
          let time = nurseRemarks[i] || {};

          const newCalculationGroup = this.fb.group({
            nurse_remark: new FormControl(time.nurse_remark ? time.nurse_remark : null),
            nurse_remark_time: new FormControl(time.nurse_remark_time ? time.nurse_remark_time : null),
            nurses: new FormControl(time.nurses||null)
          });
          CalculationArray.push(newCalculationGroup);
        }
      }
      else {
        this.addNurseRemark('add', 0);
      }
      // for (let i = 0; i < nurseRemarks?.length; i++) {
      //   this.isBathingtub = true;
      //   let CalculationArray = this.getNurseRemarks();
      //   let time = nurseRemarks[i] || {};

      //   const newCalculationGroup = this.fb.group({
      //     nurse_remark: new FormControl(time.nurse_remark ? time.nurse_remark : null),
      //     nurse_remark_time: new FormControl(time.nurse_remark_time ? time.nurse_remark_time : null)

      //   });
      //   CalculationArray.push(newCalculationGroup);
      // }
      // if (nurseRemarks?.length < 1 || nurseRemarks == undefined) {
      //   this.addNurseRemark('add', 0);
      // }
      //sleeping mother
      const sleeptime = res.data[0].nanny_task_details_json.sleeping_time;
      for (let i = 0; i < sleeptime?.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getSleepArray();
        let time = sleeptime[i] || {};

        const newCalculationGroup = this.fb.group({
          sleeping_mother_time: new FormControl(
            time.sleeping_mother_time
              ? new Date(time.sleeping_mother_time)
              : null
          ),
          sleeping_mother_actual_time: new FormControl(
            time.sleeping_mother_actual_time
              ? new Date(time.sleeping_mother_actual_time)
              : null
          ),
          sleeping_mother: new FormControl(time.sleeping_mother || ''),
          nurses: new FormControl(time.nurses||null)
        });
        CalculationArray.push(newCalculationGroup);
      }
      //nanny time
      const nannytime = res.data[0].nanny_task_details_json.nanny_time;
      for (let i = 0; i < nannytime?.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getSleepnannyArray();
        let time = nannytime[i] || {};

        const newCalculationGroup = this.fb.group({
          sleeping_nanny_time: new FormControl(
            time.sleeping_nanny_time
              ? new Date(time.sleeping_nanny_time)
              : null
          ),
          sleeping_nanny_actual_time: new FormControl(
            time.sleeping_nanny_actual_time
              ? new Date(time.sleeping_nanny_actual_time)
              : null
          ),
          sleeping_nanny: new FormControl(time.sleeping_nanny || ''),
          nurses: new FormControl(time.nurses||null)
        });
        CalculationArray.push(newCalculationGroup);
      }
      /* -------------------------------------------------------------------------- */
      /*                    set value of vitamin json into nanny task                */
      /* -------------------------------------------------------------------------- */
      for (let i = 0; i < VitaminsMedication?.length; i++) {
        let vitaminArray = this.getVitaminFormArray();
        let VitaminsMedicationFromApi = VitaminsMedication[i] || {};

        let newVitaminArray = new FormGroup({
          vitamin: new FormControl(VitaminsMedicationFromApi.vitamin || ''),
          medicine_time: new FormArray([]),
          frequency: new FormControl(VitaminsMedicationFromApi.frequency || ''),
          nurses: new FormControl(VitaminsMedicationFromApi.nurses||null)
        });

        vitaminArray.insert(i, newVitaminArray);

        this.ismedicine = true;
        for (let j = 0; j < VitaminsMedicationFromApi.medicine_time?.length; j++) {

          const viamincalc = (this.threeYearAndAboveForm.get('vitamins_medications_json') as FormArray).at(i).get('medicine_time') as FormArray;
          const bathingcalculationgroup = this.fb.group({
            time: new FormControl(new Date(VitaminsMedicationFromApi.medicine_time[j].time)),
            vitamin_checkbox: new FormControl(VitaminsMedicationFromApi.medicine_time[j].vitamin_checkbox),
            actual_time: new FormControl(VitaminsMedicationFromApi.medicine_time[j].actual_time ? new Date(VitaminsMedicationFromApi.medicine_time[j].actual_time) : ''),


            vitamin_remark: new FormControl(VitaminsMedicationFromApi.medicine_time[j].vitamin_remark),
            nurses: new FormControl(VitaminsMedicationFromApi.medicine_time[j].nurses)          });
          viamincalc.push(bathingcalculationgroup);
        }
      }
      /* -------------------------------------------------------------------------- */
      /*                    set value of vaccine json into nanny task                */
      /* -------------------------------------------------------------------------- */
      // for (let i = 0; i < vaccine?.length; i++) {
      //   let vaccinationArray = this.getVaccinationFormArray();
      //   let vaccineApi = vaccine[i] || {};

      //   let newVaccinationArray = new FormGroup({
      //     vaccination: new FormControl(vaccineApi.vaccination || ''),
      //     date: new FormControl(
      //       vaccineApi.date ? new Date(vaccineApi.date) : null
      //     ),
      //   });

      //   vaccinationArray.insert(i, newVaccinationArray);
      // }
      /* -------------------------------------------------------------------------- */
      /*                    set value of regular meal json into nanny task                */
      /* -------------------------------------------------------------------------- */
      for (let i = 0; i < regular?.length; i++) {
        let RegularArray = this.getRegularFormArray();
        let regularApi = regular[i] || {};

        let newRegularArray = new FormGroup({
          regular_meal_type: new FormControl(
            regularApi.regular_meal_type || ''
          ),
          regular_meal_time: new FormControl(
            regularApi.regular_meal_time
              ? new Date(regularApi.regular_meal_time)
              : null
          ),
          regular_meal_actual_time: new FormControl(
            regularApi.regular_meal_actual_time
              ? new Date(regularApi.regular_meal_actual_time)
              : null
          ),

          regular_meal_food_components: new FormControl(
            regularApi.regular_meal_food_components || ''
          ),
          nurses: new FormControl(regularApi.nurses||null)
        });

        RegularArray.insert(i, newRegularArray);
      }
      /* -------------------------------------------------------------------------- */
      /*                    set value of  lunch json into nanny task                         */
      /* -------------------------------------------------------------------------- */
      for (let i = 0; i < lunch?.length; i++) {
        let LunchArray = this.getLunchFormArray();
        let lunchApi = lunch[i] || {};

        let newLunchArray = new FormGroup({
          regular_meal_lunch: new FormControl(
            lunchApi.regular_meal_lunch || ''
          ),
          regular_meal_lunch_time: new FormControl(
            lunchApi.regular_meal_lunch_time
              ? new Date(lunchApi.regular_meal_lunch_time)
              : null
          ),
          regular_meal_lunch_actual_time: new FormControl(
            lunchApi.regular_meal_lunch_actual_time
              ? new Date(lunchApi.regular_meal_lunch_actual_time)
              : null
          ),
          regular_meal_lunch_food_components: new FormControl(
            lunchApi.regular_meal_lunch_food_components || ''
          ),
          nurses: new FormControl(lunchApi.nurses||null)
        });

        LunchArray.insert(i, newLunchArray);
      }
      /* -------------------------------------------------------------------------- */
      /*                    set value of  dinner json into nanny task                         */
      /* -------------------------------------------------------------------------- */
      for (let i = 0; i < dinner?.length; i++) {
        let dinnerArray = this.getDinnerFormArray();
        let dinnerApi = dinner[i] || {};

        let newdinnerArray = new FormGroup({
          regular_meal_dinner: new FormControl(
            dinnerApi.regular_meal_dinner || ''
          ),
          regular_meal_dinner_time: new FormControl(
            dinnerApi.regular_meal_dinner_time
              ? new Date(dinnerApi.regular_meal_dinner_time)
              : null
          ),
          regular_meal_dinner_actual_time: new FormControl(
            dinnerApi.regular_meal_dinner_actual_time
              ? new Date(dinnerApi.regular_meal_dinner_actual_time)
              : null
          ),
          regular_meal_dinner_food_components: new FormControl(
            dinnerApi.regular_meal_dinner_food_components || ''
          ),
          nurses: new FormControl(dinnerApi.nurses||null)
        });

        dinnerArray.insert(i, newdinnerArray);
      }
      /* -------------------------------------------------------------------------- */
      /*                    set value of  training json into nanny task                         */
      /* -------------------------------------------------------------------------- */
      for (let i = 0; i < training?.length; i++) {
        let TrainingArray = this.getTrainingFormArray();
        let trainingApi = training[i] || {};

        let newTrainingArray = new FormGroup({
          potty_training: new FormControl(trainingApi.potty_training || ''),
          training_child: new FormControl(trainingApi.training_child || ''),
          done_training_child: new FormControl(trainingApi.done_training_child || ''),
          time_training_child: new FormControl(
            trainingApi.time_training_child
              ? new Date(trainingApi.time_training_child)
              : null
          ),
          time_training_actual_child: new FormControl(
            trainingApi.time_training_actual_child
              ? new Date(trainingApi.time_training_actual_child)
              : null
          ),
          training_child_remark: new FormControl(trainingApi.training_child_remark || ''),
          nurses: new FormControl(trainingApi.nurses||null)
        });

        TrainingArray.insert(i, newTrainingArray);
      }
      /* -------------------------------------------------------------------------- */
      /*                    set value of  playing json into nanny task                         */
      /* -------------------------------------------------------------------------- */
      for (let i = 0; i < playing?.length; i++) {
        let playingArray = this.getPlayFormArray();
        let playApi = playing[i] || {};

        let newpalyingArray = new FormGroup({
          play_child_outside: new FormControl(playApi.play_child_outside || ''),
          play_child_outside_special_Instructions: new FormControl(
            playApi.play_child_outside_special_Instructions || ''
          ),
          plsy_child_outside_done: new FormControl(playApi.plsy_child_outside_done || ''),
          play_child_outside_time: new FormControl(
            playApi.play_child_outside_time
              ? new Date(playApi.play_child_outside_time)
              : null
          ),
          play_child_outside_actual_time: new FormControl(
            playApi.play_child_outside_actual_time
              ? new Date(playApi.play_child_outside_actual_time)
              : null
          ),
          play_child_outside_remark: new FormControl(playApi.play_child_outside_remark || ''),
          play_child_outside_done: new FormControl(playApi.play_child_outside_done || ''),
          nurses: new FormControl(playApi.nurses||null)
        });

        playingArray.insert(i, newpalyingArray);
      }
      //escort value
      for (let i = 0; i < escort?.length; i++) {
        let activeArray = this.getescortFormArray();
        let NewSleepingApi = escort[i] || {};

        let newTrainingArray = new FormGroup({
          escort_child_outside: new FormControl(
            NewSleepingApi.escort_child_outside || ''
          ),
          escort_child_outside_special_Instructions: new FormControl(
            NewSleepingApi.escort_child_outside_special_Instructions || ''
          ),
          escort_child_outside_done: new FormControl(NewSleepingApi.escort_child_outside_done || ''),
          escort_child_outside_time: new FormControl(
            NewSleepingApi.escort_child_outside_time
              ? new Date(NewSleepingApi.escort_child_outside_time)
              : null
          ),
          escort_child_outside_actual_time: new FormControl(
            NewSleepingApi.escort_child_outside_actual_time
              ? new Date(NewSleepingApi.escort_child_outside_actual_time)
              : null
          ),
          escort_child_outside_remark: new FormControl(NewSleepingApi.escort_child_outside_remark || ''),
          nurses: new FormControl(NewSleepingApi.nurses||null)
        });

        activeArray.insert(i, newTrainingArray);
      }
      /* -------------------------------------------------------------------------- */
      /*                    set value of  extra activities json into nanny task                         */
      /* -------------------------------------------------------------------------- */
      for (let i = 0; i < activity?.length; i++) {
        let escortArray = this.getActiveFormArray();
        let NewescortApi = activity[i] || {};

        let newescortArray = new FormGroup({
          other_activity: new FormControl(NewescortApi.other_activity || ''),
          activities_child_outside_special_Instructions: new FormControl(
            NewescortApi.activities_child_outside_special_Instructions || ''
          ),
          other_child_outside_time: new FormControl(
            NewescortApi.other_child_outside_time
              ? new Date(NewescortApi.other_child_outside_time)
              : null
          ),
          other_child_outside_actual_time: new FormControl(
            NewescortApi.other_child_outside_actual_time
              ? new Date(NewescortApi.other_child_outside_actual_time)
              : null
          ),
          other_child_outside_remark: new FormControl(NewescortApi.other_child_outside_remark || ''),
          other_child_outside_done: new FormControl(NewescortApi.other_child_outside_done || ''),
          nurses: new FormControl(NewescortApi.nurses||null)
        });

        escortArray.insert(i, newescortArray);
      }
      /* -------------------------------------------------------------------------- */
      /*                    set value of  school json into nanny task                         */
      /* -------------------------------------------------------------------------- */
      for (let i = 0; i < school?.length; i++) {
        let schoolArray = this.getSchoolFormArray();
        let schoolApi = school[i] || {};

        let newschoolArray = new FormGroup({
          escort_child_school: new FormControl(
            schoolApi.escort_child_school || ''
          ),
          escort_child_school_special_instructions: new FormControl(
            schoolApi.escort_child_school_special_instructions || ''
          ),
          school_child_outside_time: new FormControl(
            schoolApi.school_child_outside_time
              ? new Date(schoolApi.school_child_outside_time)
              : null
          ),
          school_child_outside_actual_time: new FormControl(
            schoolApi.school_child_outside_actual_time
              ? new Date(schoolApi.school_child_outside_actual_time)
              : null
          ),
          school_child_outside_remark: new FormControl(schoolApi.school_child_outside_remark || ''),
          school_child_outside_done: new FormControl(schoolApi.school_child_outside_done || ''),
          nurses: new FormControl(schoolApi.nurses||null)
        });

        schoolArray.insert(i, newschoolArray);
      }
      // set value of solid_other_snack
      this.threeYearAndAboveForm.controls.soild_other_snack_food_components.setValue(nannytask.soild_other_snack_food_components);
      this.threeYearAndAboveForm.controls.solid_other_snack_time.setValue(this.utility_service.parseISOString(nannytask.solid_other_snack_time));
      this.threeYearAndAboveForm.controls.solid_other_snack_actual_time.setValue(this.utility_service.parseISOString(nannytask.solid_other_snack_actual_time));
      // set value of regular_meal_type
      // if (nannytask.regular_meal_type == true) {
      //   this.threeYearAndAboveForm.controls['regular_meal_time'].enable();
      //   this.threeYearAndAboveForm.controls[
      //     'regular_meal_food_components'
      //   ].enable();
      //   this.threeYearAndAboveForm.controls.regular_meal_time.setValue(
      //     this.utility_service.parseISOString(nannytask.regular_meal_time)
      //   );
      //   this.threeYearAndAboveForm.controls.regular_meal_food_components.setValue(
      //     nannytask.regular_meal_food_components
      //   );
      // } else {
      //   this.threeYearAndAboveForm.controls['regular_meal_time'].disable();
      //   this.threeYearAndAboveForm.controls[
      //     'regular_meal_food_components'
      //   ].disable();
      // }
      // // set value of regular_meal_lunch
      // if (nannytask.regular_meal_lunch == true) {
      //   this.threeYearAndAboveForm.controls['regular_meal_lunch_time'].enable();
      //   this.threeYearAndAboveForm.controls[
      //     'regular_meal_lunch_food_components'
      //   ].enable();
      //   this.threeYearAndAboveForm.controls.regular_meal_lunch_time.setValue(
      //     this.utility_service.parseISOString(nannytask.regular_meal_lunch_time)
      //   );
      //   this.threeYearAndAboveForm.controls.regular_meal_lunch_food_components.setValue(
      //     nannytask.regular_meal_lunch_food_components
      //   );
      // } else {
      //   this.threeYearAndAboveForm.controls[
      //     'regular_meal_lunch_time'
      //   ].disable();
      //   this.threeYearAndAboveForm.controls[
      //     'regular_meal_lunch_food_components'
      //   ].disable();
      // }
      // //set value of regular dinner
      // if (nannytask.regular_meal_dinner == true) {
      //   this.threeYearAndAboveForm.controls[
      //     'regular_meal_dinner_time'
      //   ].enable();
      //   this.threeYearAndAboveForm.controls[
      //     'regular_meal_dinner_food_components'
      //   ].enable();
      //   this.threeYearAndAboveForm.controls.regular_meal_dinner_time.setValue(
      //     this.utility_service.parseISOString(
      //       nannytask.regular_meal_dinner_time
      //     )
      //   );
      //   this.threeYearAndAboveForm.controls.regular_meal_dinner_food_components.setValue(
      //     nannytask.regular_meal_dinner_food_components
      //   );
      // } else {
      //   this.threeYearAndAboveForm.controls[
      //     'regular_meal_dinner_time'
      //   ].disable();
      //   this.threeYearAndAboveForm.controls[
      //     'regular_meal_dinner_food_components'
      //   ].disable();
      // }
      // set value of bathing shower
      if (nannytask.bathing_shower == true) {
        this.threeYearAndAboveForm.controls['bathing_shower_time'].enable();
        this.threeYearAndAboveForm.controls[
          'bathing_shower_frequency'
        ].enable();
        // this.threeYearAndAboveForm.controls.bathing_shower_time.setValue(
        //   this.utility_service.parseISOString(nannytask.bathing_shower_time)
        // );
        this.threeYearAndAboveForm.controls.bathing_shower_frequency.setValue(
          nannytask.bathing_shower_frequency
        );
      } else {
        this.threeYearAndAboveForm.controls['bathing_shower_time'].disable();
        // this.threeYearAndAboveForm.controls[
        //   'bathing_shower_frequency'
        // ].disable();
      }
      // set value of bathing tub
      if (nannytask.bathing_tub == true) {
        this.threeYearAndAboveForm.controls['bathing_tub_time'].enable();
        this.threeYearAndAboveForm.controls['bathing_tub_frequency'].enable();
        // this.threeYearAndAboveForm.controls.bathing_tub_time.setValue(
        //   this.utility_service.parseISOString(nannytask.bathing_tub_time)
        // );
        this.threeYearAndAboveForm.controls.bathing_tub_frequency.setValue(
          nannytask.bathing_tub_frequency
        );
      } else {
        // this.threeYearAndAboveForm.controls['bathing_tub_time'].disable();
        this.threeYearAndAboveForm.controls['bathing_tub_frequency'].disable();
      }

      if (nannytask.food_medicine_allergy == '1') {
        this.threeYearAndAboveForm.controls[
          'food_medicine_allergy_specify_prod'
        ].enable();
        this.threeYearAndAboveForm.controls.food_medicine_allergy_specify_prod.setValue(
          nannytask.food_medicine_allergy_specify_prod
        );
      } else {
        this.threeYearAndAboveForm.controls[
          'food_medicine_allergy_specify_prod'
        ].disable();
      }
      this.threeYearAndAboveForm.controls.skin_care_lotion.setValue(
        nannytask.skin_care_lotion
      );
      this.threeYearAndAboveForm.controls.skin_care_baby_oil.setValue(
        nannytask.skin_care_baby_oil
      );
      this.threeYearAndAboveForm.controls.skin_care_others.setValue(
        nannytask.skin_care_others
      );
      this.threeYearAndAboveForm.controls.skin_care_specify_produ.setValue(
        nannytask.skin_care_specify_produ
      );
      this.threeYearAndAboveForm.controls.skin_care_Wipes.setValue(
        nannytask.skin_care_Wipes
      );
      this.threeYearAndAboveForm.controls.bottle_sterilization_all.setValue(
        nannytask.bottle_sterilization_all
      );
      this.threeYearAndAboveForm.controls.bottle_sterilization_all.setValue(
        nannytask.bottle_sterilization_onetime
      );

      if (nannytask.bottle_sterilization_all == true) {
        this.threeYearAndAboveForm.controls.bottle_sterilization_all_time.enable();
        this.threeYearAndAboveForm.controls.bottle_sterilization_all_time.setValue(
          this.utility_service.parseISOString(
            nannytask.bottle_sterilization_all_time
          )
        );
      } else {
        this.threeYearAndAboveForm.controls.bottle_sterilization_all.disable();
      }

      if (nannytask.skin_care_Wipes == '1') {
        this.threeYearAndAboveForm.controls['skin_care_special_instructions']
          .enable;
        this.threeYearAndAboveForm.controls.skin_care_special_instructions.setValue(
          nannytask.skin_care_special_instructions
        );
      } else {
        this.threeYearAndAboveForm.controls[
          'skin_care_special_instructions'
        ].disable();
      }

      this.threeYearAndAboveForm.controls.remarks.setValue(nannytask.remarks);
      if (nannytask.sleeping_mother == '1') {
        this.threeYearAndAboveForm.controls.sleeping_mother_time.enable();
        this.threeYearAndAboveForm.controls.sleeping_mother_time.setValue(
          this.utility_service.parseISOString(nannytask.sleeping_mother_time)
        );
      } else {
        this.threeYearAndAboveForm.controls.sleeping_mother_time.disable();
      }
      if (nannytask.sleeping_nanny == '1') {
        this.threeYearAndAboveForm.controls.sleeping_nanny_time.enable();
        this.threeYearAndAboveForm.controls.sleeping_nanny_time.setValue(
          this.utility_service.parseISOString(nannytask.sleeping_nanny_time)
        );
      } else {
        this.threeYearAndAboveForm.controls.sleeping_nanny_time.disable();
      }
      this.threeYearAndAboveForm.controls.diaper_change_frequency.setValue(nannytask.diaper_change_frequency);

      // clothes
      const changingclothes =
        res.data[0].nanny_task_details_json.changing_clothes;
      for (let i = 0; i < changingclothes?.length; i++) {
        let clothesarray = this.getClothesFormArray();
        let clothes = changingclothes[i] || {};

        const newclothesGroup = this.fb.group({
          changing_colthes: new FormControl(clothes.changing_colthes || ''),
          Changing_time: new FormControl(
            clothes.Changing_time
              ? new Date(clothes.Changing_time)
              : null
          ),

          Changing_actual_time: new FormControl(
            clothes.Changing_actual_time
              ? new Date(clothes.Changing_actual_time)
              : null
          ),
          clothe_remark: new FormControl(clothes.clothe_remark || ''),
          nurses: new FormControl(clothes.nurses||null)
        });
        clothesarray.push(newclothesGroup);
      }
      // set value of diaper changes

      //bathing section

      const tubtime = res.data[0].nanny_task_details_json.tub_bathing;
      for (let i = 0; i < tubtime?.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getbathingtubarray();
        let time = tubtime[i] || {};

        const newCalculationGroup = this.fb.group({
          bathing_tub_time: new FormControl(
            time.bathing_tub_time
              ? new Date(time.bathing_tub_time)
              : null
          ),
          bathing_tub_actual_time: new FormControl(
            time.bathing_tub_actual_time
              ? new Date(time.bathing_tub_actual_time)
              : null
          ),
          // bathing_tub_actual_time: new FormControl(
          //   new Date(time.bathing_tub_actual_time ? time.bathing_tub_actual_time : null)
          // ),
          tub_checkbox: new FormControl(time.tub_checkbox || ''),
          tubBathing_remark: new FormControl(time.tubBathing_remark || ''),
          nurses: new FormControl(time.nurses||null)
        });
        CalculationArray.push(newCalculationGroup);
      }
      const showertime = res.data[0].nanny_task_details_json.shower_bathing;
      for (let i = 0; i < showertime?.length; i++) {
        this.isBathingshower = true;
        let CalculationArray = this.getshowertubarray();
        let time = showertime[i] || {};

        const newCalculationGroup = this.fb.group({
          bathing_shower_time: new FormControl(
            time.bathing_shower_time
              ? new Date(time.bathing_shower_time)
              : null
          ),
          bathing_shower_actual_time: new FormControl(
            time.bathing_shower_actual_time
              ? new Date(time.bathing_shower_actual_time)
              : null
          ),

          shower_checkbox: new FormControl(time.shower_checkbox || ''),
          shower_remark: new FormControl(time.shower_remark || ''),
          nurses: new FormControl(time.nurses||null)
        });
        CalculationArray.push(newCalculationGroup);
      }
      this.isSettingValueProgrammatically = false;
      this.disableForm();

    }, (error: any) => {
      this.spinner.hide()

      // Handle any errors that occurred during the API call
      

    });
  }
  /* -------------------------------------------------------------------------- */
  /*                        form submission function                            */
  /* -------------------------------------------------------------------------- */
  savethreeYearAndAboveForm(threeYearForm: any) {
    this.threeYearAndAboveForm.enable();
    let structuredObject = {
      baby_details_id: this.threeYearAndAboveForm.get('baby_details_id').value,
      remarks:this.threeYearAndAboveForm.get('remarks').value,
      nurse_remarks:this.threeYearAndAboveForm.get('nurse_remarks').value,
      bathing:[
        {
         
          bathing_tub:this.threeYearAndAboveForm.get('bathing_tub').value,
          bathing_tub_frequency:this.threeYearAndAboveForm.get('bathing_tub_frequency').value,
          tub_bathing:this.threeYearAndAboveForm.get('tub_bathing').value,
          bathing_shower:this.threeYearAndAboveForm.get('bathing_shower').value,
          bathing_shower_frequency:this.threeYearAndAboveForm.get('bathing_shower_frequency').value,
          shower_bathing:this.threeYearAndAboveForm.get('shower_bathing').value,
        }
      ],
    
       clothes:[
        {
        changing_clothes:this.threeYearAndAboveForm.get('changing_clothes').value,
        changing_colthes_spiecal_instructions:this.threeYearAndAboveForm.get('changing_colthes_spiecal_instructions').value,
       }
      ],
      prepared:[
        {
          feeding_prepared_mother:this.threeYearAndAboveForm.get('feeding_prepared_mother').value,
          feeding_prepared_nanny:this.threeYearAndAboveForm.get('feeding_prepared_nanny').value,
           feeding_prepared_other: this.threeYearAndAboveForm.get('feeding_prepared_other').value,
        }
      ],
      sleeping:[{
        sleeping_time:this.threeYearAndAboveForm.get('sleeping_time').value,
        nanny_time:this.threeYearAndAboveForm.get('nanny_time').value,
      }],
      skin:[{
        skin_care:this.threeYearAndAboveForm.get('skin_care').value,
      }],
      vitamin:[
        {
          vitamins_medications_json:this.threeYearAndAboveForm.get('vitamins_medications_json').value,
        }
      ],
      wipes:[
        {
          skin_care_special_instructions:this.threeYearAndAboveForm.get('skin_care_special_instructions').value,
          skin_care_Wipes:this.threeYearAndAboveForm.get('skin_care_Wipes').value,
        }
      ],
      food:[{
        solid_food:this.threeYearAndAboveForm.get('regular_food').value,
        lunch_food:this.threeYearAndAboveForm.get('lunch_food').value,
        dinner_food:this.threeYearAndAboveForm.get('dinner_food').value,
        solid_other_snack_time:this.threeYearAndAboveForm.get('solid_other_snack_time').value,
        soild_other_snack_food_components:this.threeYearAndAboveForm.get('soild_other_snack_food_components').value,
      }],
      allergy:[
        {
          food_medicine_allergy:this.threeYearAndAboveForm.get('food_medicine_allergy').value,
          food_medicine_allergy_specify_prod:this.threeYearAndAboveForm.get('food_medicine_allergy_specify_prod').value,
        }
      ],
  
      potty_training:[
        {
          potty_training:this.threeYearAndAboveForm.get('potty_training').value,
        }
      ],
      activities:[
        {
          outside_child:this.threeYearAndAboveForm.get('outside_child').value,
          escort_child:this.threeYearAndAboveForm.get('escort_child').value,
          activities_name:this.threeYearAndAboveForm.get('activities_name').value,
          school_name:this.threeYearAndAboveForm.get('school_name').value,
        }
      ]

  };
    this.spinner.show()
   
    window.scroll(0, 0);
    if (threeYearForm.valid) {
      let data = JSON.parse(JSON.stringify(this.threeYearAndAboveForm.value));
      // let data = { ...this.threeYearAndAboveForm.value };
      data.baby_plan_id = 4;
      data.vaccinations_json = JSON.stringify(data?.vaccinations_json);
      data.vitamins_medications_json = JSON.stringify(
        data?.vitamins_medications_json
      );

      let timeArray = [
        'bottle_sterilization_onetime_time',
        // 'solid_other_snack_time',
        'regular_meal_time',
        'regular_meal_lunch_time',
        'regular_meal_dinner_time',
        'sleeping_mother_time',
        'sleeping_nanny_time',
        'feeding_time',
        'bathing_tub_time',
        'bathing_shower_time',
        'nap_time',
        'sleep_time',
        'solid_other_snack_time',
      ];
      // Filter out fields with no values
      let payload = {
        nanny_task_details_json: JSON.stringify(
          this.threeYearAndAboveForm.value
        ),
        baby_plan_id: 4,
        baby_details_id: this.threeYearAndAboveForm.value.baby_details_id,
        structured_json: JSON.stringify(structuredObject),
      };
      // for (const key in data) {
      //   if (data[key] !== null && data[key] !== undefined && data[key] !== '' && data[key] !== "") {
      //     payload[key] = data[key];
      //   }
      // }
      // for (let i = 0; i < timeArray?.length; i++) {
      //   payload[timeArray[i]] = payload[timeArray[i]]
      //     ? moment(payload[timeArray[i]]).format('h:mm:ss a')
      //     : '';
      // }
      const jsonString: string = JSON.stringify(this.threeYearAndAboveForm.value);
      // Correctly call the method from the service
      const processedData = this.utility_service.processFormData(JSON.parse(jsonString));
      console.log("Processed Data: ", processedData);
      payload.nanny_task_details_json = JSON.stringify(processedData)
      
      data.sleep_training_json = JSON.stringify([
        { sleep_training: data?.sleep_training },
        { wake_up_time: data?.wake_up_time },
        { sleep_time: data?.sleep_time },
        { nap_time: data?.nap_time },
      ]);
      delete data['sleep_training'];
      delete data['wake_up_time'];
      delete data['sleep_time'];
      delete data['nap_time'];
      // Modify the structure for the frequency properties
      data.bathing_shower_frequency = data?.bathing_shower_frequency?.frequency;
      data.bathing_tub_frequency = data?.bathing_tub_frequency?.frequency;
      data.changing_colthes_frequency =
        data?.changing_colthes_frequency?.frequency?.frequency;
      data.feeding_frequency = data?.feeding_frequency?.frequency;
      data.temperature_monitoring_frequency =
        data?.temperature_monitoring_frequency?.frequency;

      // this.spinner.show();
      let id = localStorage.getItem('nurseTaskEditID');
      if (this.isedit == true) {
        this.common_service.putEditNurseDailytask(payload, id).then(
          (res: any) => {
            this.spinner.hide()
           
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: res?.message,
            });
            // threeYearForm.reset();
            setTimeout(() => {
              this.router.navigateByUrl('/portal/first-screen')
            }, 400);
            // this.spinner.hide();
          },
          (error) => {

           
            this.spinner.hide()

            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error,
            });
          }
        );
      } else {
        // this.spinner.show();
        this.common_service.postBabyDailyTaskDetail(payload).then(
          (res: any) => {
           
            this.spinner.hide()

            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: res?.message,
            });
            // threeYearForm.reset();
            setTimeout(() => {
              this.router.navigateByUrl('/portal/first-screen')
            }, 400);
            // this.spinner.hide();
          },
          (error) => {
           
            this.spinner.hide()

            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error,
            });
          }
        );
      }
    } else {
      this.spinner.hide()

      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Fill All the Details!',
      });
    }
  }
  disableAllRegularFoodControls(checkboxValue: boolean, index: number): void {
    const regularFoodArray = this.getRegularFormArray();
    const control = regularFoodArray.at(index) as FormGroup;

    if (checkboxValue) {
      // Disable controls when checkbox is checked
      control.get('regular_meal_food_components').enable();
      // Add validators if needed
      // control.get('regular_meal_time').setValidators([Validators.required]);
      // control.get('regular_meal_food_components').setValidators([Validators.required]);
    } else {
      // Disable controls when checkbox is unchecked
      control.get('regular_meal_time').disable();
      control.get('regular_meal_food_components').disable();
      // Clear validators and set values to null
      control.get('regular_meal_time').clearValidators();
      control.get('regular_meal_food_components').clearValidators();
      // control.get('regular_meal_time').setValue(null);
      // control.get('regular_meal_food_components').setValue(null);
    }

    // Update validity
    control.get('regular_meal_time').updateValueAndValidity();
    control.get('regular_meal_food_components').updateValueAndValidity();
  }


  disableAlllunchFoodControls(checkboxValue: boolean, index: number): void {
    const lunchFoodArray = this.getLunchFormArray();
    const control = lunchFoodArray.at(index) as FormGroup;

    if (checkboxValue) {
      // Disable controls when checkbox is checked

      control.get('regular_meal_lunch_food_components').enable();
    } else {
      // Enable controls when checkbox is unchecked
      control.get('regular_meal_lunch_time').disable();
      control.get('regular_meal_lunch_food_components').disable();
      // Clear validators and set values to null
      control.get('regular_meal_lunch_time').clearValidators();
      control.get('regular_meal_lunch_food_components').clearValidators();
      // control.get('regular_meal_lunch_time').setValue(null);
      // control.get('regular_meal_lunch_food_components').setValue(null);
    }

    // Update validity
    control.get('regular_meal_lunch_time').updateValueAndValidity();
    control.get('regular_meal_lunch_food_components').updateValueAndValidity();
  }

  disableAlldinnerFoodControls(checkboxValue: boolean, index: number): void {
    const regularFoodArray = this.getDinnerFormArray();

    // for (let i = 0; i < regularFoodArray?.length; i++) {
    const control = regularFoodArray.at(index) as FormGroup;

    if (checkboxValue) {
      // Disable controls and set values to null when checkbox is checked

      control.get('regular_meal_dinner_food_components').enable();


    } else {
      // Enable controls when checkbox is unchecked
      control.get('regular_meal_dinner_time').disable();
      control.get('regular_meal_dinner_food_components').disable();
      control.get('regular_meal_dinner_time').clearValidators();
      control.get('regular_meal_dinner_food_components').clearValidators();
      // control.get('regular_meal_dinner_time').setValue(null);
      // control.get('regular_meal_dinner_food_components').setValue(null);
    }
    control.get('regular_meal_dinner_time').updateValueAndValidity();
    control.get('regular_meal_dinner_food_components').updateValueAndValidity();

  }
  handleInput(event: any, value: string) {

    if (value == 'snacks') {
      if (event.target.checked) {
        // this.threeYearAndAboveForm.controls.solid_other_snack_time.enable();
        this.threeYearAndAboveForm.controls.solid_other_snack_actual_time.enable();
        this.threeYearAndAboveForm.controls.soild_other_snack_food_components.enable();
      } else {
        // this.threeYearAndAboveForm.controls.solid_other_snack_time.disable();
        this.threeYearAndAboveForm.controls.solid_other_snack_actual_time.disable();
        this.threeYearAndAboveForm.controls.soild_other_snack_food_components.disable();
        // this.threeYearAndAboveForm.controls['solid_other_snack_time'].setValue(
        //   ''
        // );
        // this.threeYearAndAboveForm.controls['solid_other_snack_actual_time'].setValue(
        //   ''
        // );
        // this.threeYearAndAboveForm.controls[
        //   'soild_other_snack_food_components'
        // ].setValue('');
      }
      if (event.target.checked) {
        this.threeYearAndAboveForm.controls[
          'solid_other_snack_time'
        ].setValidators([Validators.required]);
        this.threeYearAndAboveForm.controls[
          'solid_other_snack_actual_time'
        ].setValidators([Validators.required]);
        this.threeYearAndAboveForm.controls[
          'soild_other_snack_food_components'
        ].setValidators([Validators.required]);
      } else {
        // this.threeYearAndAboveForm.controls[
        //   'solid_other_snack_time'
        // ].clearValidators();
        this.threeYearAndAboveForm.controls[
          'solid_other_snack_actual_time'
        ].clearValidators();
        this.threeYearAndAboveForm.controls[
          'soild_other_snack_food_components'
        ].clearValidators();
      }
      // this.threeYearAndAboveForm.controls[
      //   'solid_other_snack_time'
      // ].updateValueAndValidity();
      this.threeYearAndAboveForm.controls[
        'solid_other_snack_actual_time'
      ].updateValueAndValidity();
      this.threeYearAndAboveForm.controls[
        'soild_other_snack_food_components'
      ].updateValueAndValidity();
    } else if (value == 'regularMeal') {
      if (event.target.value == 'regularMeal') {
        if (event.target.checked) {
          this.threeYearAndAboveForm.controls.regular_meal_food_components.enable();
        } else {
          this.threeYearAndAboveForm.controls.regular_meal_time.disable();
          this.threeYearAndAboveForm.controls.regular_meal_food_components.disable();
          this.threeYearAndAboveForm.controls['regular_meal_time'].setValue('');
          this.threeYearAndAboveForm.controls[
            'regular_meal_food_components'
          ].setValue('');
        }
        if (event.target.checked) {
          this.threeYearAndAboveForm.controls[
            'regular_meal_time'
          ].setValidators([Validators.required]);
          this.threeYearAndAboveForm.controls[
            'regular_meal_food_components'
          ].setValidators([Validators.required]);
        } else {
          this.threeYearAndAboveForm.controls[
            'regular_meal_time'
          ].clearValidators();
          this.threeYearAndAboveForm.controls[
            'regular_meal_food_components'
          ].clearValidators();
        }
        this.threeYearAndAboveForm.controls[
          'regular_meal_time'
        ].updateValueAndValidity();
        this.threeYearAndAboveForm.controls[
          'regular_meal_food_components'
        ].updateValueAndValidity();
      }
      if (event.target.value == 'lunch') {
        if (event.target.checked) {
          this.threeYearAndAboveForm.controls.regular_meal_lunch_food_components.enable();
        } else {
          this.threeYearAndAboveForm.controls.regular_meal_lunch_time.disable();
          this.threeYearAndAboveForm.controls.regular_meal_lunch_food_components.disable();
          this.threeYearAndAboveForm.controls[
            'regular_meal_lunch_time'
          ].setValue('');
          this.threeYearAndAboveForm.controls[
            'regular_meal_lunch_food_components'
          ].setValue('');
        }
        if (event.target.checked) {
          this.threeYearAndAboveForm.controls[
            'regular_meal_lunch_time'
          ].setValidators([Validators.required]);
          this.threeYearAndAboveForm.controls[
            'regular_meal_lunch_food_components'
          ].setValidators([Validators.required]);
        } else {
          this.threeYearAndAboveForm.controls[
            'regular_meal_lunch_time'
          ].clearValidators();
          this.threeYearAndAboveForm.controls[
            'regular_meal_lunch_food_components'
          ].clearValidators();
        }
        this.threeYearAndAboveForm.controls[
          'regular_meal_lunch_time'
        ].updateValueAndValidity();
        this.threeYearAndAboveForm.controls[
          'regular_meal_lunch_food_components'
        ].updateValueAndValidity();
      }
      if (event.target.value == 'dinner') {
        if (event.target.checked) {
          this.threeYearAndAboveForm.controls.regular_meal_dinner_food_components.enable();
        } else {
          this.threeYearAndAboveForm.controls.regular_meal_dinner_time.disable();
          this.threeYearAndAboveForm.controls.regular_meal_dinner_food_components.disable();
          this.threeYearAndAboveForm.controls[
            'regular_meal_dinner_time'
          ].setValue('');
          this.threeYearAndAboveForm.controls[
            'regular_meal_dinner_food_components'
          ].setValue('');
        }
      }
      if (event.target.checked) {
        this.threeYearAndAboveForm.controls[
          'regular_meal_dinner_time'
        ].setValidators([Validators.required]);
        this.threeYearAndAboveForm.controls[
          'regular_meal_dinner_food_components'
        ].setValidators([Validators.required]);
      } else {
        this.threeYearAndAboveForm.controls[
          'regular_meal_dinner_time'
        ].clearValidators();
        this.threeYearAndAboveForm.controls[
          'regular_meal_dinner_food_components'
        ].clearValidators();
      }
      this.threeYearAndAboveForm.controls[
        'regular_meal_dinner_time'
      ].updateValueAndValidity();
      this.threeYearAndAboveForm.controls[
        'regular_meal_dinner_food_components'
      ].updateValueAndValidity();
    } else if (value == 'fnmAllergy') {
      if (event.target.value == '1') {
        this.threeYearAndAboveForm.controls.food_medicine_allergy_specify_prod.enable();
      } else {
        this.threeYearAndAboveForm.controls.food_medicine_allergy_specify_prod.disable();
        this.threeYearAndAboveForm.controls.food_medicine_allergy_specify_prod.setValue(
          null
        );
      }
    } else if (value == 'bathing') {
      if (event.target.checked) {
        this.threeYearAndAboveForm.controls.bathing_tub_frequency.enable();
        this.threeYearAndAboveForm.controls.bathing_tub_time.enable();
      } else {
        this.threeYearAndAboveForm.controls.bathing_tub_frequency.disable();
        // this.threeYearAndAboveForm.controls.bathing_tub_time.disable();
        this.threeYearAndAboveForm.controls['bathing_tub_frequency'].setValue(
          ''
        );
        this.threeYearAndAboveForm.controls['bathing_tub_time'].setValue('');
      }
      if (event.target.checked) {
        this.threeYearAndAboveForm.controls[
          'bathing_tub_frequency'
        ].setValidators([Validators.required]);
        // this.threeYearAndAboveForm.controls['bathing_tub_time'].setValidators([
        //   Validators.required,
        // ]);
      } else {
        this.threeYearAndAboveForm.controls[
          'bathing_tub_frequency'
        ].clearValidators();
        this.threeYearAndAboveForm.controls[
          'bathing_tub_time'
        ].clearValidators();
      }
      this.threeYearAndAboveForm.controls[
        'bathing_tub_frequency'
      ].updateValueAndValidity();
      this.threeYearAndAboveForm.controls[
        'bathing_tub_time'
      ].updateValueAndValidity();
    } else if (value == 'showerbath') {
      if (event.target.checked) {
        this.threeYearAndAboveForm.controls.bathing_shower_frequency.enable();
        this.threeYearAndAboveForm.controls.bathing_shower_time.enable();
      } else {
        this.threeYearAndAboveForm.controls.bathing_shower_frequency.disable();
        // this.threeYearAndAboveForm.controls.bathing_shower_time.disable();
        this.threeYearAndAboveForm.controls[
          'bathing_shower_frequency'
        ].setValue('');
        this.threeYearAndAboveForm.controls['bathing_shower_time'].setValue('');
      }
      if (event.target.checked) {
        this.threeYearAndAboveForm.controls[
          'bathing_shower_frequency'
        ].setValidators([Validators.required]);
        // this.threeYearAndAboveForm.controls[
        //   'bathing_shower_time'
        // ].setValidators([Validators.required]);
      } else {
        this.threeYearAndAboveForm.controls[
          'bathing_shower_frequency'
        ].clearValidators();
        this.threeYearAndAboveForm.controls[
          'bathing_shower_time'
        ].clearValidators();
      }
      this.threeYearAndAboveForm.controls[
        'bathing_shower_frequency'
      ].updateValueAndValidity();
      this.threeYearAndAboveForm.controls[
        'bathing_shower_time'
      ].updateValueAndValidity();
    } else if (value == 'otherSkinCare' && event.target.value == 'skin-care') {
      this.skinCare = !this.skinCare;
    } else if (value == 'sleeping-with-mother') {
      if (event.target.value == 'sleep-with-mother' && event.target.checked) {
        this.threeYearAndAboveForm.controls.sleeping_mother_time.enable();
      } else {
        this.threeYearAndAboveForm.controls.sleeping_mother_time.disable();
        this.threeYearAndAboveForm.controls.sleeping_mother_time.setValue('');
      }
      if (event.target.checked) {

      } else {
        this.threeYearAndAboveForm.controls[
          'sleeping_mother_time'
        ].clearValidators();
      }
      this.threeYearAndAboveForm.controls[
        'sleeping_mother_time'
      ].updateValueAndValidity();
    } else if (value == 'sleeping-with-nanny') {
      if (event.target.checked && event.target.value == 'sleep-with-nanny') {
        this.threeYearAndAboveForm.controls.sleeping_nanny_time.enable();
      } else {
        this.threeYearAndAboveForm.controls.sleeping_nanny_time.disable();
        this.threeYearAndAboveForm.controls.sleeping_nanny_time.setValue('');
      }
      if (event.target.checked) {

      }
      else {
        this.threeYearAndAboveForm.controls[
          'sleeping_nanny_time'
        ].clearValidators();
      }
      this.threeYearAndAboveForm.controls[
        'sleeping_nanny_time'
      ].updateValueAndValidity();
    } else if (value == 'wipes') {
      if (event.target.value == '1' && event.target.checked) {
        this.threeYearAndAboveForm.controls.skin_care_special_instructions.enable();
      } else if (event.target.value == '0' && event.target.checked) {
        this.threeYearAndAboveForm.controls.skin_care_special_instructions.disable();
        this.threeYearAndAboveForm.controls[
          'skin_care_special_instructions'
        ].setValue(null);
      }
    } else if (value == 'skin') {
      if (event.target.value == '0' && event.target.checked) {
        this.threeYearAndAboveForm.controls.skin_care_specify_produ.disable();
        this.threeYearAndAboveForm.controls['skin_care_specify_produ'].setValue(
          null
        );
      } else if (event.target.value == '1') {
        this.threeYearAndAboveForm.controls.skin_care_specify_produ.enable();
      }
    } else if (value == 'training') {
      if (event.target.value == 'yes') {
        this.sleepTraining = false;
      } else if (event.target.value == 'no') {
        this.sleepTraining = true;
        this.threeYearAndAboveForm.controls['wake_up_time'].setValue('');
        this.threeYearAndAboveForm.controls['nap_time'].setValue('');
        this.threeYearAndAboveForm.controls['sleep_time'].setValue('');
      }
    } else if (value == 'playing') {
      if (event.target.value == '1') {
        this.playWith = false;
      } else if (event.target.value == '0') {
        this.playWith = true;
        this.threeYearAndAboveForm.controls[
          'play_child_outside_special_Instructions'
        ].setValue('');
      }
    } else if (value == 'escort') {
      if (event.target.value == '1') {
        this.escort = false;
      } else if (event.target.value == '0') {
        this.escort = true;
        this.threeYearAndAboveForm.controls[
          'escort_child_outside_special_Instructions'
        ].setValue('');
      }
    } else if (value == 'escortSchool') {
      if (event.target.value == '1') {
        this.escortSchool = false;
      } else if (event.target.value == '0') {
        this.escortSchool = true;
        this.threeYearAndAboveForm.controls[
          'escort_child_school_special_Instructions'
        ].setValue('');
      }
    }
  }
  /* -------------------------------------------------------------------------- */
  /*                     // get vitamins form array function                     */
  /* -------------------------------------------------------------------------- */
  getVitaminFormArray() {
    return this.threeYearAndAboveForm.get(
      'vitamins_medications_json'
    ) as FormArray;
  }
  /* -------------------------------------------------------------------------- */
  /*                   // get vaccinaacion form array function                  */
  /* -------------------------------------------------------------------------- */
  // getVaccinationFormArray() {
  //   return this.threeYearAndAboveForm.get('vaccinations_json') as FormArray;
  // }
  /* -------------------------------------------------------------------------- */
  /*                 // add vaccinations on click plus function                 */
  /* -------------------------------------------------------------------------- */
  // addVaccinationObject(string: any, index: any) {
  //   let vaccinationArray = this.getVaccinationFormArray();
  //   if (string == 'add') {
  //     let newVaccinationArray = new FormGroup({
  //       vaccination: new FormControl(''),
  //       date: new FormControl(''),
  //     });
  //     vaccinationArray.insert(index + 1, newVaccinationArray);
  //   } else {
  //     vaccinationArray.removeAt(index);
  //   }
  // }
  getAllUserPermission() {
    let id = localStorage.getItem('user_id');
    this.masterService.getUserPremissionById(id).then((res: any) => {

      this.permissionData = res.data;

      for (let i = 0; i < this.permissionData?.length; i++) {
        
        this.permissions.push(this.permissionData[i].permission_id);
      }
    })
  }

  checkPermission(permission: number) {
   
    if (this.permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
    // return this.permissions.includes(permission);
  }
  /* -------------------------------------------------------------------------- */
  /*                   // add vitamins on click plus function                   */
  /* -------------------------------------------------------------------------- */
  addVitaminObject(string: any, index: any) {
    let vitaminArray = this.getVitaminFormArray();
    if (string == 'add') {
      let newVitaminArray = new FormGroup({
        vitamin: new FormControl(''),
        medicine_time: new FormArray([]),
        frequency: new FormControl(''),
        nurses: new FormControl(null)
      });
      vitaminArray.insert(index + 1, newVitaminArray);
    } else {
      vitaminArray.removeAt(index);
    }
  }
  //get regular meal from breakfast array
  getRegularFormArray() {
    return this.threeYearAndAboveForm.get('regular_food') as FormArray;
  }
  /* -------------------------------------------------------------------------- */
  /*                   // add breakfast on click plus function                   */
  /* -------------------------------------------------------------------------- */
  addRegularObject(string: any, index: any) {
    let RegularArray = this.getRegularFormArray();
    if (string == 'add') {
      let newRegularArray = new FormGroup({
        regular_meal_type: new FormControl(''),
        regular_meal_time: new FormControl(''),
        regular_meal_actual_time: new FormControl(),
        regular_meal_food_components: new FormControl(''),
        nurses: new FormControl(null)
      });
      RegularArray.insert(index + 1, newRegularArray);
    } else {
      RegularArray.removeAt(index);
    }
  }
  //get lunch value from lunch food array
  getLunchFormArray() {
    return this.threeYearAndAboveForm.get('lunch_food') as FormArray;
  }
  /* -------------------------------------------------------------------------- */
  /*                   // add lunch on click plus function                   */
  /* -------------------------------------------------------------------------- */
  addLunchObject(string: any, index: any) {
    let LunchArray = this.getLunchFormArray();
    if (string == 'add') {
      let newLunchArray = new FormGroup({
        regular_meal_lunch: new FormControl(''),
        regular_meal_lunch_time: new FormControl(''),
        regular_meal_lunch_actual_time: new FormControl(),
        regular_meal_lunch_food_components: new FormControl(''),
        nurses: new FormControl(null)
      });
      LunchArray.insert(index + 1, newLunchArray);
    } else {
      LunchArray.removeAt(index);
    }
  }
  //get dinner value from dinner food array
  getDinnerFormArray() {
    return this.threeYearAndAboveForm.get('dinner_food') as FormArray;
  }
  /* -------------------------------------------------------------------------- */
  /*                   // add dinner on click plus function                   */
  /* -------------------------------------------------------------------------- */
  addDinnerObject(string: any, index: any) {
    let DinnerArray = this.getDinnerFormArray();
    if (string == 'add') {
      let newDinnerArray = new FormGroup({
        regular_meal_dinner: new FormControl(''),
        regular_meal_dinner_time: new FormControl(''),
        regular_meal_dinner_actual_time: new FormControl(),
        regular_meal_dinner_food_components: new FormControl(''),
        nurses: new FormControl(null)
      });
      DinnerArray.insert(index + 1, newDinnerArray);
    } else {
      DinnerArray.removeAt(index);
    }
  }
  //get value of playing activities
  getPlayFormArray() {
    return this.threeYearAndAboveForm.get('outside_child') as FormArray;
  }
  /* -------------------------------------------------------------------------- */
  /*                  // add Playing activites on click plus function                   */
  /* -------------------------------------------------------------------------- */
  addPlayObject(string: any, index: any) {
    let playArray = this.getPlayFormArray();
    if (string == 'add') {
      let newplayArray = new FormGroup({
        play_child_outside: new FormControl(''),
        play_child_outside_special_Instructions: new FormControl(''),
        play_child_outside_time: new FormControl(),
        play_child_outside_actual_time: new FormControl(),
        play_child_outside_remark: new FormControl(),
        play_child_outside_done: new FormControl(),
        nurses: new FormControl(null)
      });
      playArray.insert(index + 1, newplayArray);
    } else {
      playArray.removeAt(index);
    }
  }
  //get value of escort
  getescortFormArray() {
    return this.threeYearAndAboveForm.get('escort_child') as FormArray;
  }
  /* -------------------------------------------------------------------------- */
  /*                   // add escort activites on click plus function                   */
  /* -------------------------------------------------------------------------- */
  addEscortObject(string: any, index: any) {
    let escortArray = this.getescortFormArray();
    if (string == 'add') {
      let newescortArray = new FormGroup({
        escort_child_outside: new FormControl(''),
        escort_child_outside_special_Instructions: new FormControl(''),
        escort_child_outside_time: new FormControl(),
        escort_child_outside_actual_time: new FormControl(),
        escort_child_outside_remark: new FormControl(),
        escort_child_outside_done: new FormControl(),
        nurses: new FormControl(null)
      });
      escortArray.insert(index + 1, newescortArray);
    } else {
      escortArray.removeAt(index);
    }
  }
  //get value of other activity
  getActiveFormArray() {
    return this.threeYearAndAboveForm.get('activities_name') as FormArray;
  }
  /* -------------------------------------------------------------------------- */
  /*                   // add other activites on click plus function                   */
  /* -------------------------------------------------------------------------- */
  addActiveObject(string: any, index: any) {
    let activityArray = this.getActiveFormArray();
    if (string == 'add') {
      let newactivityArray = new FormGroup({
        other_activity: new FormControl(''),
        activities_child_outside_special_Instructions: new FormControl(''),
        other_child_outside_time: new FormControl(),
        other_child_outside_remark: new FormControl(),
        other_child_outside_done: new FormControl(),
        other_child_outside_actual_time: new FormControl(),
        nurses: new FormControl(null)
      });
      activityArray.insert(index + 1, newactivityArray);
    } else {
      activityArray.removeAt(index);
    }
  }
  //get value of escort school activity
  getSchoolFormArray() {
    return this.threeYearAndAboveForm.get('school_name') as FormArray;
  }
  /* -------------------------------------------------------------------------- */
  /*                   // add school activites on click plus function                   */
  /* -------------------------------------------------------------------------- */
  addSchoolObject(string: any, index: any) {
    let schoolArray = this.getSchoolFormArray();
    if (string == 'add') {
      let newschoolArray = new FormGroup({
        escort_child_school: new FormControl(''),
        escort_child_school_special_instructions: new FormControl(''),
        school_child_outside_time: new FormControl(),
        school_child_outside_actual_time: new FormControl(),
        school_child_outside_remark: new FormControl(),
        school_child_outside_done: new FormControl(),
        nurses: new FormControl(null)
      });
      schoolArray.insert(index + 1, newschoolArray);
    } else {
      schoolArray.removeAt(index);
    }
  }
  //get training value
  getNurseRemarks() {
    return this.threeYearAndAboveForm.get('nurse_remarks') as FormArray;
  }
  getTrainingFormArray() {
    return this.threeYearAndAboveForm.get('child_training') as FormArray;
  }
  /* -------------------------------------------------------------------------- */
  /*                   // add training activites on click plus function                   */
  /* -------------------------------------------------------------------------- */
  addTrainingObject(string: any, index: any) {
    let TrainingArray = this.getTrainingFormArray();
    if (string == 'add') {
      let newTrainingArray = new FormGroup({
        potty_training: new FormControl(''),
        training_child: new FormControl(''),
        done_training_child: new FormControl(null),
        time_training_child: new FormControl(null),
        time_training_actual_child: new FormControl(null),
        training_child_remark: new FormControl(null),
        nurses: new FormControl(null)
      });
      TrainingArray.insert(index + 1, newTrainingArray);
    } else {
      TrainingArray.removeAt(index);
    }
  }
  /* -------------------------------------------------------------------------- */
  /*                   // add skin care  on click plus function                   */
  /* -------------------------------------------------------------------------- */
  addSkinObject(string: any, index: any) {
    let skinArray = this.getSkinFormArray();
    if (string == 'add') {
      let newskinArray = new FormGroup({
        skin_care_lotion: new FormControl(''),
        skin_care_baby_oil: new FormControl(''),
        skin_care_others: new FormControl(''),
        skin_care_specify_produ: new FormControl(''),
        nurses: new FormControl(null)
      });
      skinArray.insert(index + 1, newskinArray);
    } else {
      skinArray.removeAt(index);
    }
  }
  getSkinFormArray() {
    return this.threeYearAndAboveForm.get('skin_care') as FormArray;
  }
  getbathingtubarray() {
    return this.threeYearAndAboveForm.get('tub_bathing') as FormArray;
  }
  isset(variable) {
    if (variable != 'undefined' && variable != null && variable != '') {
      return true;
    } else {
      return false;
    }
  }
  clearbathingtubcalculation() {
    (
      this.threeYearAndAboveForm.get('tub_bathing') as FormArray
    ).clear();
  }
  addbathingtubcalculation(): void {
    const bathingcalculationtub = this.getbathingtubarray();
    const bathingcalculationgroup1 = this.fb.group({
      tub_checkbox: new FormControl(),
      bathing_tub_time: new FormControl(),
      bathing_tub_actual_time: new FormControl(),
      tubBathing_remark: new FormControl(),
      nurses: new FormControl(null)
    });
    bathingcalculationtub.push(bathingcalculationgroup1);
  }
  addtubfield() {

    if (!this.isSettingValueProgrammatically) {
      let frequency = this.threeYearAndAboveForm.controls.bathing_tub_frequency.value.frequency;


      if (this.isset(frequency)) {

        let array = this.getbathingtubarray();
        this.isBathingtub = true;
        this.clearbathingtubcalculation();

        for (let i = 0; i < frequency; i++) {
          this.addbathingtubcalculation();
        }
      }
    }
  }
  getshowertubarray() {
    return this.threeYearAndAboveForm.get('shower_bathing') as FormArray;
  }
  clearshowercalculation() {
    (
      this.threeYearAndAboveForm.get('shower_bathing') as FormArray
    ).clear();
  }
  addshowercalculation(): void {
    const bathingcalculationtub = this.getshowertubarray();
    const bathingcalculationgroup1 = this.fb.group({
      bathing_shower_time: new FormControl(),
      bathing_shower_actual_time: new FormControl(),
      shower_remark: new FormControl(),
      shower_checkbox: new FormControl(),
      nurses: new FormControl(null)
    });
    bathingcalculationtub.push(bathingcalculationgroup1);
  }
  addshowerfield() {

    if (!this.isSettingValueProgrammatically) {
      let frequency = this.threeYearAndAboveForm.controls.bathing_shower_frequency.value.frequency;


      if (this.isset(frequency)) {

        let array = this.getshowertubarray();
        this.isBathingshower = true;
        this.clearshowercalculation();

        for (let i = 0; i < frequency; i++) {
          this.addshowercalculation();
        }
      }
    }
  }
  getmedicinearray(index) {
    return (this.threeYearAndAboveForm.get('vitamins_medications_json') as FormArray).at(index).get('medicine_time') as FormArray;
  }
  clearmedicinecalculation(i) {
    ((this.threeYearAndAboveForm.get('vitamins_medications_json') as FormArray).at(i).get('medicine_time') as FormArray).clear();
  }
  addmedicationcalculation(index: number): void {

    const viamincalc = (this.threeYearAndAboveForm.get('vitamins_medications_json') as FormArray).at(index).get('medicine_time') as FormArray;
    const bathingcalculationgroup = this.fb.group({
      time: new FormControl(),
      vitamin_checkbox: new FormControl(null),
      vitamin_remark: new FormControl(null),
      actual_time: new FormControl(null),
      nurses: new FormControl(null)
    });
    viamincalc.push(bathingcalculationgroup);
  }
  getFrequency(index: number) {
    let vitaminFormArray = this.getVitaminFormArray();
    if (vitaminFormArray && vitaminFormArray.controls?.length > index) {
      let vitaminscontrols = vitaminFormArray.controls[index] as FormGroup;
      let frequency = vitaminscontrols.get('frequency').value;

      return frequency;
    }
    return null; // or any default value if needed
  }
  addmedicinefield(index: number) {

    if (!this.isSettingValueProgrammatically) {
      let frequency = this.getFrequency(index); // Replace 0 with the desired index

      if (this.isset(frequency)) {

        // let array = this.getmedicinearray();
        this.ismedicine = true;
        this.clearmedicinecalculation(index);

        for (let i = 0; i < frequency; i++) {
          this.addmedicationcalculation(index);
        }
      }
    }
  }
  getClothesFormArray() {
    return this.threeYearAndAboveForm.get('changing_clothes') as FormArray;
  }
  addClothesObject(string: any, index: any) {
    let ClothesArray = this.getClothesFormArray();
    if (string == 'add') {
      let newclothesArray = new FormGroup({
        changing_colthes: new FormControl(''),
        Changing_time: new FormControl(''),
        Changing_actual_time: new FormControl(''),
        clothe_remark: new FormControl(''),
        nurses: new FormControl(null)
      });
      ClothesArray.insert(index + 1, newclothesArray);
    } else {
      ClothesArray.removeAt(index);
    }
  }
  addsleeptimeObject(string: any, index: any) {
    let vitaminArray = this.getSleepArray();
    if (string == 'add') {
      let newVitaminArray = new FormGroup({
        sleeping_mother: new FormControl(''),
        sleeping_mother_time: new FormControl(''),
        sleeping_mother_actual_time: new FormControl(''),
        nurses: new FormControl(null)
      });

      // let formatedTime =  moment(newTime).format('HH:mm:ss');
      vitaminArray.insert(index + 1, newVitaminArray);
    } else {
      vitaminArray.removeAt(index);
    }
  }
  addnannyObject(string: any, index: any) {
    let vitaminArray = this.getSleepnannyArray();
    if (string == 'add') {
      let newVitaminArray = new FormGroup({
        sleeping_nanny: new FormControl(''),
        sleeping_nanny_time: new FormControl(''),
        sleeping_nanny_actual_time: new FormControl(''),
        nurses: new FormControl(null)
      });

      // let formatedTime =  moment(newTime).format('HH:mm:ss');
      vitaminArray.insert(index + 1, newVitaminArray);
    } else {
      vitaminArray.removeAt(index);
    }
  }
  getSleepArray(): FormArray {
    return this.threeYearAndAboveForm.get('sleeping_time') as FormArray;
  }
  getSleepnannyArray(): FormArray {
    return this.threeYearAndAboveForm.get('nanny_time') as FormArray;
  }

  addNurseRemark(string: any, index: any) {
    let remarkArray = this.getNurseRemarks();
    const currentDate = new Date();

    let hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();

    const amOrPm = hours >= 12 ? 'PM' : 'AM';

    if (hours > 12) {
      hours -= 12;
    }

    if (string == 'add') {
      let newremarkArray = new FormGroup({
        nurse_remark: new FormControl(''),
        nurse_remark_time: new FormControl(`${hours}:${minutes}:${seconds} ${amOrPm}`),
        nurses: new FormControl(null)
      });
      remarkArray.insert(index + 1, newremarkArray);
    } else {
      remarkArray.removeAt(index);
    }
  }

  disableForm() {
    const formData = this.threeYearAndAboveForm.value;
    for (let key in formData) {
      if ((formData[key] !== null && formData[key] !== ' ') && formData[key] !== undefined) {
        if (Array.isArray(formData[key])) {
          let isenabled = true;
          for (let nestedKey in formData[key]) {
            // if (nestedKey !== '0' && formData[key][nestedKey] !== null && formData[key][nestedKey] !== undefined) {
            const control = this.threeYearAndAboveForm.get(`${key}.${nestedKey}`);
            // if(isenabled == false && (formData[key][nestedKey] == null || formData[key][nestedKey] == "")) {
            if (key == "vitamins_medications_json") {

              for (let innerKey in formData[key][nestedKey]) {
                if (Array.isArray(formData[key][nestedKey][innerKey])) {
                  const innercontrol = this.threeYearAndAboveForm.get(`${key}.${nestedKey}.${innerKey}`);
                  let isenabled1 = true;
                  for (let innergroup in formData[key][nestedKey][innerKey]) {
                    const innercontrol1 = this.threeYearAndAboveForm.get(`${key}.${nestedKey}.${innerKey}.${innergroup}`);
                    if (innercontrol1.get('vitamin_checkbox').value != true && isenabled1 && innercontrol1.get('vitamin_remark').value == null) {
                      if (innercontrol1) {
                        innercontrol1.enable();
                        isenabled1 = false;
                      }
                    }
                    else {
                      if (innercontrol1) {
                        innercontrol1.disable();
                      }
                    }
                  }

                }
              }

            }
            else if(key == "nurse_remarks") {
              const nurseRemarkControl = control.get('nurse_remark');
              const nurseRemarkValue = nurseRemarkControl.value;
          
          
              if (nurseRemarkValue !== null && nurseRemarkValue !== '') {
                  nurseRemarkControl.disable();
              } else {
                  nurseRemarkControl.enable();
              }
          }
            else if (this.checkcontrol(control, ["shower_checkbox", "feeding_breast_checkbox", "feeding_checkbox", "sleeping_mother", "done_sterilization", "isDiaperChanged", "change", "sponge_checkbox", "tub_checkbox", "changing_colthes", "vitamin_checkbox", "regular_meal_type", "regular_meal_lunch", "regular_meal_dinner", "regular_meal_snack", "sleeping_nanny", "done_training_child", "play_child_outside_done", "escort_child_outside_done", "other_child_outside_done","school_child_outside_done","escort_child_school","diaper_checkbox"],['breast_feeding_remark','formula_remark','remark','sterilization_remark','diaper_remark',  'shower_remark', 'tubBathing_remark', 'clothe_remark', 'vitamin_remark', 'sleeping_nanny_remark', 'diaperRemark','training_child_remark','play_child_outside_remark','escort_child_outside_remark','other_child_outside_remark','school_child_outside_remark']) && isenabled) {
              if (control) {
                control.enable();
                isenabled = false;
              }
            }
            else {
              if (control) {
                control.disable();
              }
            }
          }
        } else {
          const control = this.threeYearAndAboveForm.get(key);
          if (control) {
            // control.disable();
          }
        }
      }
    }
  }

  checkcontrol(control, name,remarks) {
    let a
    for (let i = 0; i < name?.length; i++) {
      if (name[i], control.get(name[i])?.value != true && control.get(name[i])?.value != undefined || control.get(name[i])?.value === null) {
        for (let j = 0; j < remarks?.length; j++) {
          if (control.get(remarks[j])?.value != '' && control.get(remarks[j])?.value != undefined) {
            return false;
          }
        }
        return true;
      }
    }
    return false;

  }
  formatDateTime(date: string | undefined): string {
    if (date) {
      const formattedDate = new Date(date);
      // const year = formattedDate.getFullYear();
      // const month = ('0' + (formattedDate.getMonth() + 1)).slice(-2);
      // const day = ('0' + formattedDate.getDate()).slice(-2);
      const hours = ('0' + formattedDate.getHours()).slice(-2);
      const minutes = ('0' + formattedDate.getMinutes()).slice(-2);
      const seconds = ('0' + formattedDate.getSeconds()).slice(-2);

      return `${hours}:${minutes}:${seconds}`;
      // return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }
    return '-';
  }
}
